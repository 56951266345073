import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import CommonLandingPage from "./CommonLandingPage";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ChevronRight from "../../assets/images/Chevron-left.svg";
import styled from "styled-components";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";

/**
 * NOTE: Structured Component
 * @description This Component is a forget password page.
 * @return {*}
 */

const Text = styled.p`
  font-family: "Normal";
  margin: 0;
`;

const InputField = styled.input`
  font-family: "Normal";
  font-size: 16px;
  font-weight: 500;
  color: #fafafa;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  &::placeholder {
    color: #b0b0b0;
  }
`;

const InputFieldContainer = styled.div`
  padding: 11px 12px;
  width: 100%;
  border-radius: 12px;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #2f2f2f;
`;

const StyledButton = styled.button`
  border-radius: 12px;
  background-color: #47ccd6;
  border: 1px solid #47ccd6;
  padding: 13px;
  color: #ececec;
  font-family: "Normal";
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  min-height: 56px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.5;
    background: #5e5e5e;
    border-color: #5e5e5e;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover {
      background: #40909a;
      border-color: #40909a;
    }

    &:active {
      background: #47ccd6;
      border-color: #47ccd6;
    }
  }
`;

const ForgetPassword = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const checkEmail = () => {
    const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

    if (!emailRegex.test(email)) {
      setIsEmailValid(false);
      setErrorMessage("Please enter a valid email address.");
      return false;
    } else {
      return true;
    }
  };

  const handleClickHandler = async () => {
    setIsEmailValid(true);
    setErrorMessage("");

    // Input Fields Validation
    let checkEmailResponse = await checkEmail();

    if (checkEmailResponse === true) {
      try {
        let stringifiedData = JSON.stringify({
          clientId: env.REACT_APP_CLIENT_ID,
          emailId: email,
        });

        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "admin/generateOtp",
          stringifiedData,
        );

        history.push("/forgetPasswordOtpVerification", { email: email });
      } catch (err) {
        if (err?.response?.data?.code === 409) {
          setErrorMessage(err?.response?.data.remarks);
          setIsEmailValid(false);
        } else {
          setErrorMessage("Failed to send OTP. Please try again later.");
        }
      }
    }
  };

  return (
    <CommonLandingPage>
      <CustomRow>
        <Column style={{ marginBottom: "40px" }}>
          <Text
            style={{ color: "#F6F6F6", fontSize: "22px", lineHeight: "31px" }}
          >
            Forgot your password? No problem. We will send an email with
            instructions to reset your password along with OTP.
          </Text>
        </Column>
        <Column style={{ marginBottom: errorMessage ? "10px" : "40px" }}>
          <InputFieldContainer
            style={!isEmailValid ? { borderColor: "#EA3A3A" } : {}}
          >
            <InputField
              placeholder="Work Email"
              name="email"
              onChange={handleEmailChange}
            />
          </InputFieldContainer>
        </Column>
        {errorMessage && (
          <Column style={{ marginBottom: "12px" }}>
            <Text style={{ color: "#EA3A3A", fontSize: "12px" }}>
              {errorMessage}
            </Text>
          </Column>
        )}
        <Column style={{ marginBottom: "40px" }}>
          {/* <StyledButton onClick={handleClickHandler} disabled={!email}>
            Send OTP
          </StyledButton> */}
          <StyledButtonPrimary
            onClick={handleClickHandler}
            disabled={!email}
            style={{ fontFamily: "Normal" }}
          >
            Send OTP
          </StyledButtonPrimary>
        </Column>
        <Column>
          <Link to="/login">
            <Text style={{ color: "#CCCCCC", fontSize: "16px" }}>
              <img
                src={ChevronRight}
                alt="chevron"
                style={{ height: "12px", width: "12px", marginRight: "10px" }}
              />
              Back to login
            </Text>
          </Link>
        </Column>
      </CustomRow>
    </CommonLandingPage>
  );
};

export default withRouter(ForgetPassword);
