import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import SuccessIcon from "../../assets/images/Success.svg";
import ErrorIcon from "../../assets/images/sync_problem_24dp_FILL0_wght400_GRAD0_opsz24 1.svg";
import { io } from "socket.io-client";
import { env } from "../../env";
import {
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ReactJson from "@microlink/react-json-view";
import InProgressIcon from "../../assets/images/reset_wrench_24dp_FILL0_wght400_GRAD0_opsz24 1.svg";
import BackIcon from "../../assets/images/left_arrow_icon.svg";
import PublishConnectorModal from "../ApplicationModal/publishConnectorModal";
import BuildConfigIcon from "../../assets/images/build_config_icon.svg";
import BuildLogsIcon from "../../assets/images/build_logs_icon.svg";
import BuildStatusIcon from "../../assets/images/build_status_icon.svg";
import ConfigureEnvIcon from "../../assets/images/configure_env_icon.svg";
import ChevronRight from "../../assets/images/chevron_right.svg";
import PlusIcon from "../../assets/images/plus_icon.svg";
import CustomRow from "../common/_customRow";
import Column from "../common/_customColumn";
import ToggleButton from "../common/_toggleButtonNew";
import GenericSelection from "../common/_dropDownComponent";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import GenericModal from "../common/_genericModal";
import axiosAdapter from "../../utils";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../common/_buttonNewOne";

const MainContainer = styled.div`
  background-color: #171717;
  height: 100vh;
  width: 100vw;
  padding: 0px 16px;
`;

const TopNavigationSection = styled.div`
  width: 100%;
  padding: 12px 0px;
  height: 80px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  background-color: #171717;
`;

const PageContentSection = styled.div`
  width: 100%;
  height: calc(100vh - 216px);
  background-color: #171717;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 16px;
    overflow: auto;
  }
`;

const PageContentLeftSection = styled.div`
  height: 100%;
  background-color: #171717;
  width: 39%;
  @media (max-width: 768px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PageContentRightSection = styled.div`
  height: 100%;
  background-color: #2f2f2f;
  border-radius: 12px;
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
  overflow: auto;
  padding: 20px;
`;

const BottomButtonSection = styled.div`
  width: 100%;
  height: 80px;
  padding: 0px 16px;
  background-color: #171717;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

const Text = styled.p`
  font-family: "Normal-Demi-Bold";
  font-weight: 600;
  color: #f6f6f6;
  font-size: 24px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ConfigSection = styled.div`
  width: 100%;
  height: 65%;
  overflow: auto;
  background-color: #2f2f2f;
  /* background-color: pink; */
  border-radius: 12px;
  padding: 16px;
`;

const BuildResultSection = styled.div`
  width: 100%;
  height: 34%;
  overflow: auto;
  background-color: #2f2f2f;
  border-radius: 12px;
  padding: 16px;
`;

const BuildFieldSection = styled.div`
  width: 100%;
  /* min-height: calc(100% - 72px); */
  background-color: #2f2f2f;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  gap: 32px;
  @media (max-width: 838px) {
    grid-template-columns: 2fr;
  }
`;

const ConfigFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledButton = styled.button`
  border: 1px solid #ececec;
  border-radius: 12px;
  background-color: inherit;
  padding: 5px 25px;
  color: #ececec;
  font-family: "Normal";
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  max-width: calc(1.2 * 200px);
  min-width: calc(0.9 * 200px);
  &:disabled {
    opacity: 0.5;
  }
  /* &:hover {
    background: #40909a;
    border-color: #40909a;
  }

  &:active {
    background: #47ccd6;
    border-color: #47ccd6;
  } */
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.div`
  color: #b0b0b0;
  font-family: "Normal";
  font-size: 14px;
  font-weight: 500;
`;

const InputFieldContainer = styled.div`
  padding: 14px 12px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #b0b0b0;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputField = styled.input`
  font-family: "Normal";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #fafafa;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;

  &::placeholder {
    color: #777777;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  outline: none;
  background-color: inherit;
  resize: none;
  border-radius: 12px;
  border: 1px solid #b0b0b0;
  color: #fafafa;
  font-size: 14px;
  font-family: Normal;

  &::placeholder {
    color: #777777;
  }
`;

export default function BuildLogsAndConfiguration() {
  const location = useLocation();
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  console.log(location.state);
  const socket = useRef(null);
  const [logs, setLogs] = useState([]);
  const [buildStatus, setBuildStatus] = useState("In Progress");
  const [errorResponse, setErrorResponse] = useState("");
  const [buildId, setBuildId] = useState(null);
  const [isPublishModalOpen, setPublishModalOpen] = useState(false);
  const [isConfigureStep, setConfigureStep] = useState(false);
  const [envConfigs, setEnvConfigs] = useState([]);
  const [selectedEnvIndex, setSelectedEnvIndex] = useState(-1);
  const [configSaveLoading, setConfigSaveLoading] = useState(false);
  const [isConfigSaveErrorModalOpen, setConfigSaveErrorModalOpen] =
    useState(false);

  useEffect(() => {
    socket.current = io(env.REACT_APP_URL, {
      auth: {
        token: localStorage.getItem("token"),
      },
      path: "/docker/sockets",
    });

    const buildDockerImageResponseListener = (data) => {
      if (data.code !== 200) {
        setBuildStatus("Failed");
        setErrorResponse(data.message);
      } else {
        setBuildStatus("Success");
        setBuildId(data?.data?.buildId);
      }
    };

    const buildDockerImageLogListener = (data) => {
      if (
        JSON.stringify({
          stream: "\n",
        }) !== JSON.stringify(data)
      ) {
        setLogs((prevLogs) => [...prevLogs, data]);
      }
    };

    const socketDisconnectListner = (reason) => {
      if (reason === "io server disconnect") {
        socket.current.connect();
      }
    };

    if (socket.current) {
      socket.current.on(
        "buildDockerImageResponse",
        buildDockerImageResponseListener,
      );
      socket.current.on("buildDockerImageLog", buildDockerImageLogListener);
      socket.current.on("disconnect", socketDisconnectListner);

      socket.current.emit("/buildDockerImage", {
        imageName: location.state?.imageName,
        tag: location.state?.tagName,
        workspaceId: parseInt(localStorage.getItem("selectedWorkSpaceId")),
        inputType: "GIT",
        repoName: location.state?.repoName,
        branch: location.state?.branch,
        username: location.state?.username,
        applicationId: location.state?.applicationId,
      });
    }

    return () => {
      if (socket.current) {
        socket.current.off(
          "buildDockerImageResponse",
          buildDockerImageResponseListener,
        );
        socket.current.off("buildDockerImageLog", buildDockerImageLogListener);
        socket.current.off("disconnect", socketDisconnectListner);
        socket.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const handleModal = () => {
    setPublishModalOpen(true);
  };
  // Redirect to homepage when there is no indication that this page was opened through redirection
  if (!Boolean(location.state?.isRedirected))
    return <Redirect to={{ pathname: "/" }} />;

  const handleConfigSave = async () => {
    setConfigSaveLoading(true);
    let envNameMapper = new Map();
    try {
      for (let i = 0; i < envConfigs.length; i++) {
        if (
          envConfigs[i].name === "" ||
          envConfigs[i].label === "" ||
          envConfigs[i].placeholder === ""
        ) {
          let error = new Error(
            `Please fill all the fields marked '*' for each configuration variable or remove the configuration variable from the list`,
          );
          error.code = "INPUT_ERROR";
          throw error;
        } else if (!/^[a-zA-Z0-9-_]+$/.test(envConfigs[i].name)) {
          let error = new Error(
            `Please fill the Environment Variable Name properly for '${envConfigs[i].label}'`,
          );
          error.code = "INPUT_ERROR";
          throw error;
        }
        if (envNameMapper.has(envConfigs[i].name)) {
          let error = new Error(
            `All the Environment Variable Names must be unique`,
          );
          error.code = "INPUT_ERROR";
          throw error;
        } else envNameMapper.set(envConfigs[i].name, true);
      }
      let configs = envConfigs.map((config) => ({
        name: config.name,
        label: config.label,
        placeholder: config.placeholder,
        type: config.type.value,
        description: config.description,
        isRequired: config.isRequired,
        ...(config.type.value === "topic"
          ? {
              direction: config.direction,
            }
          : {}),
      }));
      let stringifiedData = JSON.stringify({
        buildId,
        config: !configs.some((config) => config.label === "Title")
          ? [
              {
                name: "title",
                label: "Title",
                placeholder: "Title",
                type: "text",
                description: "Application Title",
                isRequired: true,
              },
              {
                name: "portNumber",
                label: "Port Number",
                placeholder: "Enter Port",
                type: "port",
                description: "Port number for the application deployment",
                isRequired: true,
                min: 8000,
                max: 10000,
              },
              ...configs,
            ]
          : [...configs],
      });
      let updateApplicationResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/updateBuildUserConfigs",
        stringifiedData,
      );
      if (updateApplicationResponse.data.status !== "OK") {
        let error = new Error(updateApplicationResponse.data.remarks);
        error.code = "INPUT_ERROR";
        throw error;
      }
      setConfigSaveLoading(false);
      handleModal();
    } catch (err) {
      console.log("Err in Config Save", err);
      setConfigSaveLoading(false);
      if (err.code === "INPUT_ERROR") {
        showMessage(err.message);
      } else setConfigSaveErrorModalOpen(true);
    }
  };
  return (
    <>
      <MainContainer>
        <TopNavigationSection>
          <img
            src={BackIcon}
            style={{ height: "32px", width: "32px", cursor: "pointer" }}
            alt="Go Back"
            onClick={() =>
              buildStatus !== "In Progress" && !configSaveLoading
                ? history.push("/configApplication", {
                    isRedirected: true,
                    applicationId: location.state?.applicationId,
                  })
                : null
            }
          />
          <Text style={{ fontFamily: "Normal-Demi-Bold" }}>
            Configure Deployment
          </Text>
        </TopNavigationSection>
        <PageContentSection>
          <PageContentLeftSection>
            <ConfigSection>
              <Text
                style={{
                  fontSize: "20px",
                  marginBottom: "32px",
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  fontFamily: "Normal-Demi-Bold",
                }}
              >
                <img
                  src={BuildConfigIcon}
                  alt="Settings Icon"
                  style={{ height: "32px", width: "32px" }}
                />
                Add your Configurations
              </Text>
              <BuildFieldSection>
                <ConfigFieldContainer>
                  <Text
                    style={{
                      fontSize: "16px",
                      color: "#777777",
                      fontWeight: "500",
                    }}
                  >
                    Image Name
                  </Text>
                  <Text
                    style={{
                      fontSize: "18px",
                      fontFamily: "Normal-Demi-Bold",
                    }}
                  >
                    {location.state?.imageName || "NA"}
                  </Text>
                </ConfigFieldContainer>
                <ConfigFieldContainer>
                  <Text
                    style={{
                      fontSize: "16px",
                      color: "#777777",
                      fontWeight: "500",
                    }}
                  >
                    Tag Name
                  </Text>
                  <Text
                    style={{
                      fontSize: "18px",
                      fontFamily: "Normal-Demi-Bold",
                    }}
                  >
                    {location.state?.tagName || "NA"}
                  </Text>
                </ConfigFieldContainer>
                <ConfigFieldContainer>
                  <Text
                    style={{
                      fontSize: "16px",
                      color: "#777777",
                      fontWeight: "500",
                    }}
                  >
                    Commit Name
                  </Text>
                  <Text
                    style={{
                      fontSize: "18px",
                      fontFamily: "Normal-Demi-Bold",
                    }}
                  >
                    {location.state?.commitName || "NA"}
                  </Text>
                </ConfigFieldContainer>
                <ConfigFieldContainer>
                  <Text
                    style={{
                      fontSize: "16px",
                      color: "#777777",
                      fontWeight: "500",
                    }}
                  >
                    Branch Name
                  </Text>
                  <Text
                    style={{
                      fontSize: "18px",
                      fontFamily: "Normal-Demi-Bold",
                    }}
                  >
                    {location.state?.branch ?? "NA"}
                  </Text>
                </ConfigFieldContainer>
                <ConfigFieldContainer>
                  <Text
                    style={{
                      fontSize: "16px",
                      color: "#777777",
                      fontWeight: "500",
                    }}
                  >
                    Repository Name
                  </Text>
                  <Text
                    style={{
                      fontSize: "18px",
                      fontFamily: "Normal-Demi-Bold",
                    }}
                  >
                    {location.state?.repoName ?? "NA"}
                  </Text>
                </ConfigFieldContainer>
              </BuildFieldSection>
            </ConfigSection>
            <BuildResultSection>
              <Text
                style={{
                  marginBottom: "30px",
                  display: "flex",
                  fontSize: "20px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "12px",
                  fontFamily: "Normal-Demi-Bold",
                }}
              >
                <img src={BuildStatusIcon} alt="Status Icon" />
                Build Result
              </Text>
              <div
                style={{
                  display: "flex",
                  gap: "24px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  maxWidth: "90%",
                }}
              >
                <img
                  src={
                    buildStatus === "In Progress"
                      ? InProgressIcon
                      : buildStatus === "Failed"
                      ? ErrorIcon
                      : SuccessIcon
                  }
                  style={{ height: "64px", width: "64px" }}
                  alt="Status Icon"
                />
                <Text
                  style={{
                    fontSize: "16px",
                    fontStyle: "italic",
                    fontWeight: "500",
                    color: "#CCCCCC",
                  }}
                >
                  {buildStatus === "In Progress"
                    ? "The build is currently in progress, please wait for the build to be completed to view result"
                    : buildStatus === "Failed"
                    ? errorResponse
                    : "The build is successful. Please declare the Environment Variables to continue"}
                </Text>
              </div>
            </BuildResultSection>
          </PageContentLeftSection>
          <PageContentRightSection
            style={{ ...(isConfigureStep ? { padding: 0 } : {}) }}
          >
            {!isConfigureStep && (
              <Text
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "flex-start",
                  gap: "12px",
                  fontSize: "20px",
                  marginBottom: "30px",
                  fontFamily: "Normal-Demi-Bold",
                }}
              >
                <img src={BuildLogsIcon} alt="Logs Icon" />
                Logs
              </Text>
            )}
            {!isConfigureStep && (
              <ReactJson
                src={logs}
                theme={"twilight"}
                indentWidth={3}
                groupArraysAfterLength={1000}
                displayObjectSize={false}
                displayDataTypes={false}
                quotesOnKeys={false}
                displayArrayKey={false}
                style={{
                  backgroundColor: "#2F2F2F",
                  borderRadius: "10px",
                  fontFamily: "'Fira Code', Consolas, 'Courier New', monospace",
                }}
              />
            )}
            {isConfigureStep && (
              <>
                <CustomRow
                  style={{ padding: "16px", borderBottom: "1px solid #4B4B4B" }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "12px",
                    }}
                  >
                    <img src={ConfigureEnvIcon} alt="Configure" />
                    Configure Environmental Variable
                  </Text>
                  <img
                    src={PlusIcon}
                    alt="Plus Icon"
                    style={{ height: "20px", width: "20px", cursor: "pointer" }}
                    onClick={() => {
                      setEnvConfigs((prev) => [
                        ...prev,
                        {
                          name: "",
                          label: "",
                          type: { name: "Text", value: "text" },
                          placeholder: "",
                          description: "",
                          isRequired: true,
                          direction: "input",
                        },
                      ]);
                      if (selectedEnvIndex === -1) setSelectedEnvIndex(0);
                    }}
                  />
                </CustomRow>
                <CustomRow style={{ height: "calc(100% - 66px)" }}>
                  <Column
                    xs={4}
                    style={{
                      height: "100%",
                      backgroundColor: "#2F2F2F",
                      overflow: "auto",
                      borderRight: "1px solid #4B4B4B",
                    }}
                  >
                    <CustomRow
                      style={{
                        justifyContent: "flex-start",
                        gap: "8px",
                        padding: "12px",
                      }}
                    >
                      {envConfigs.map((env, index) => (
                        <Column
                          style={{
                            backgroundColor: "#2F2F2F",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "7px",
                            border: "1px solid #2F2F2F",
                            cursor: "pointer",
                            ...(index === selectedEnvIndex
                              ? {
                                  border: "1px solid #4B4B4B",
                                  backgroundColor: "#4B4B4B",
                                }
                              : {}),
                          }}
                          onClick={() => setSelectedEnvIndex(index)}
                        >
                          <Text style={{ fontSize: "16px" }}>
                            {env.label || `Configure Name ${index + 1}`}
                          </Text>
                          <img
                            src={ChevronRight}
                            alt="right"
                            style={{ height: "24px", width: "24px" }}
                          />
                        </Column>
                      ))}
                    </CustomRow>
                  </Column>
                  <Column
                    xs={8}
                    style={{
                      height: "100%",
                      backgroundColor: "#2F2F2F",
                      overflow: "auto",
                    }}
                  >
                    {selectedEnvIndex !== -1 ? (
                      <CustomRow
                        style={{
                          padding: "16px",
                          gap: "20px",
                        }}
                      >
                        <Column
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text style={{ fontSize: "20px" }}>
                            Variable Details
                          </Text>
                          <Text
                            style={{
                              fontSize: "14px",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (envConfigs.length > 1) {
                                setEnvConfigs((prev) =>
                                  prev.filter(
                                    (_, idx) => idx !== selectedEnvIndex,
                                  ),
                                );
                                if (selectedEnvIndex === 0)
                                  setSelectedEnvIndex(0);
                                else setSelectedEnvIndex((prev) => prev - 1);
                              } else {
                                setEnvConfigs([]);
                                setSelectedEnvIndex(-1);
                              }
                            }}
                          >
                            Delete
                          </Text>
                        </Column>
                        <Column xs={12} lg={5.7}>
                          <InputContainer>
                            <Label>Environment Variable Name *</Label>
                            <InputFieldContainer>
                              <InputField
                                name="name"
                                value={envConfigs[selectedEnvIndex].name}
                                onChange={(e) =>
                                  e.target.value.length < 31 &&
                                  setEnvConfigs((prev) => {
                                    let temp = [...prev];
                                    temp[selectedEnvIndex].name =
                                      e.target.value;
                                    return temp;
                                  })
                                }
                              />
                              <Text
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "16px",
                                  color: "#B0B0B0",
                                  overflow: "visible",
                                  whiteSpace: "normal",
                                  textOverflow: "clip",
                                }}
                              >
                                {`${envConfigs[selectedEnvIndex].name.length}/30`}
                              </Text>
                            </InputFieldContainer>
                          </InputContainer>
                        </Column>
                        <Column xs={12} lg={5.7}>
                          <InputContainer>
                            <Label>Required</Label>
                            <ToggleButton
                              text1={"Yes"}
                              text2={"No"}
                              value1={true}
                              value2={false}
                              toggleValue={
                                envConfigs[selectedEnvIndex].isRequired
                              }
                              toggleFunction={(value) => {
                                setEnvConfigs((prev) => {
                                  let temp = [...prev];
                                  temp[selectedEnvIndex].isRequired = value;
                                  return temp;
                                });
                              }}
                            />
                          </InputContainer>
                        </Column>
                        <Column>
                          <InputContainer>
                            <Label>Configuration Name *</Label>
                            <InputFieldContainer>
                              <InputField
                                name="name"
                                value={envConfigs[selectedEnvIndex].label}
                                onChange={(e) =>
                                  setEnvConfigs((prev) => {
                                    let temp = [...prev];
                                    temp[selectedEnvIndex].label =
                                      e.target.value;
                                    return temp;
                                  })
                                }
                              />
                            </InputFieldContainer>
                          </InputContainer>
                        </Column>
                        <Column xs={12} lg={5.7}>
                          <InputContainer>
                            <Label>Type *</Label>
                            <GenericSelection
                              dropdownItems={[
                                { name: "Text", value: "text" },
                                { name: "Number", value: "number" },
                                { name: "Password", value: "password" },
                                { name: "Topic", value: "topic" },
                              ]}
                              selectedItem={envConfigs[selectedEnvIndex].type}
                              placeholder={"Select a Type"}
                              onSelect={(item) => {
                                setEnvConfigs((prev) => {
                                  let temp = [...prev];
                                  temp[selectedEnvIndex].type = item;
                                  return temp;
                                });
                              }}
                              nameFunction={(item) => item.name}
                              filterFunction={(arr, text) =>
                                arr.filter((item) =>
                                  item.name
                                    .toLowerCase()
                                    .includes(text.toLowerCase()),
                                )
                              }
                              searchPlaceHolder={"Search for Types"}
                              noMargin={true}
                            />
                          </InputContainer>
                        </Column>
                        <Column xs={12} lg={5.7}>
                          <InputContainer>
                            <Label>Topic Type</Label>
                            <ToggleButton
                              text1={"Input"}
                              text2={"Output"}
                              value1={"input"}
                              value2={"output"}
                              toggleValue={
                                envConfigs[selectedEnvIndex].direction
                              }
                              toggleFunction={(value) => {
                                setEnvConfigs((prev) => {
                                  let temp = [...prev];
                                  temp[selectedEnvIndex].direction = value;
                                  return temp;
                                });
                              }}
                              isDisabled={
                                envConfigs[selectedEnvIndex].type.value !==
                                "topic"
                              }
                            />
                          </InputContainer>
                        </Column>
                        <Column xs={12}>
                          <InputContainer>
                            <Label>Placeholder *</Label>
                            <InputFieldContainer>
                              <InputField
                                name="placeholder"
                                value={envConfigs[selectedEnvIndex].placeholder}
                                onChange={(e) =>
                                  setEnvConfigs((prev) => {
                                    let temp = [...prev];
                                    temp[selectedEnvIndex].placeholder =
                                      e.target.value;
                                    return temp;
                                  })
                                }
                              />
                            </InputFieldContainer>
                          </InputContainer>
                        </Column>
                        <Column xs={12}>
                          <InputContainer>
                            <Label>Description</Label>
                            <TextArea
                              rows={2}
                              value={envConfigs[selectedEnvIndex].description}
                              onChange={(e) => {
                                setEnvConfigs((prev) => {
                                  let temp = [...prev];
                                  temp[selectedEnvIndex].description =
                                    e.target.value;
                                  return temp;
                                });
                              }}
                            />
                          </InputContainer>
                        </Column>
                      </CustomRow>
                    ) : (
                      <CustomRow style={{ padding: "16px", gap: "20px" }}>
                        <Column>
                          <Text
                            style={{
                              overflow: "visible",
                              whiteSpace: "normal",
                              textOverflow: "clip",
                            }}
                          >
                            Click on the '{" "}
                            <img src={PlusIcon} alt="Plus Icon" /> ' above to
                            add a new configuration
                          </Text>
                        </Column>
                        <Column>
                          <Text
                            style={{
                              overflow: "visible",
                              whiteSpace: "normal",
                              textOverflow: "clip",
                            }}
                          >
                            Not adding any configurations will save empty
                            configurations with your application
                          </Text>
                        </Column>
                      </CustomRow>
                    )}
                  </Column>
                </CustomRow>
              </>
            )}
          </PageContentRightSection>
        </PageContentSection>
        <BottomButtonSection>
          {/* <StyledButton
            disabled={buildStatus === "In Progress" || configSaveLoading}
            onClick={() =>
              history.push("/configApplication", {
                isRedirected: true,
                applicationId: location.state?.applicationId,
              })
            }
          >
            Back
          </StyledButton> */}
          <StyledButtonsecondary
            disabled={buildStatus === "In Progress" || configSaveLoading}
            onClick={() =>
              history.push("/configApplication", {
                isRedirected: true,
                applicationId: location.state?.applicationId,
              })
            }
            style={{ width: "204px" }}
          >
            Back
          </StyledButtonsecondary>
          {/* <StyledButton
            style={{
              backgroundColor: buildStatus === "Failed" ? "#EA3A3A" : "#47CCD6",
              border: "none",
            }}
            disabled={buildStatus === "In Progress" || configSaveLoading}
            onClick={() => {
              if (buildStatus === "Failed") {
                history.push("/configApplication", {
                  isRedirected: true,
                  applicationId: location.state?.applicationId,
                });
              } else {
                if (!isConfigureStep) {
                  setConfigureStep(true);
                } else {
                  handleConfigSave();
                }
              }
            }}
          >
            {buildStatus === "Failed"
              ? "Try Again"
              : isConfigureStep
              ? "Save"
              : "Configure ENVs"}
          </StyledButton> */}
          <StyledButtonPrimary
            style={{
              // backgroundColor: buildStatus === "Failed" ? "#EA3A3A" : "#47CCD6",
              border: "none",
              width: "204px",
            }}
            disabled={buildStatus === "In Progress" || configSaveLoading}
            onClick={() => {
              if (buildStatus === "Failed") {
                history.push("/configApplication", {
                  isRedirected: true,
                  applicationId: location.state?.applicationId,
                });
              } else {
                if (!isConfigureStep) {
                  setConfigureStep(true);
                } else {
                  handleConfigSave();
                }
              }
            }}
          >
            {buildStatus === "Failed"
              ? "Try Again"
              : isConfigureStep
              ? "Save"
              : "Configure ENVs"}
          </StyledButtonPrimary>
        </BottomButtonSection>
        {isPublishModalOpen && (
          <PublishConnectorModal
            isModelOpen={isPublishModalOpen}
            setIsModalOpen={setPublishModalOpen}
            buildId={buildId}
            name={location.state?.imageName}
            tagName={location.state?.tagName}
            repositoryName={location.state?.repoName}
            applicationId={location.state?.applicationId}
          ></PublishConnectorModal>
        )}
      </MainContainer>
      <GenericModal
        show={isConfigSaveErrorModalOpen}
        title={"Your ENVs couldn't be configured."}
      >
        <CustomRow>
          <Column>
            <Text
              style={{
                marginBottom: "80px",
                overflow: "visible",
                whiteSpace: "normal",
                textOverflow: "clip",
                opacity: "0.7",
                color: "#ECECEC",
                fontSize: "18px",
              }}
            >
              There was a problem in saving your ENVs due to an error. Please
              try again.
            </Text>
          </Column>
          <Column
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
              alignItems: "center",
            }}
          >
            {/* <StyledButton onClick={() => setConfigSaveErrorModalOpen(false)}>
              Cancel
            </StyledButton> */}
            <StyledButtonsecondary
              onClick={() => setConfigSaveErrorModalOpen(false)}
              style={{ width: "204px" }}
            >
              Cancel
            </StyledButtonsecondary>
            {/* <StyledButton
              style={{
                backgroundColor: "#47CCD6",
                border: "none",
              }}
              onClick={() => {
                setConfigSaveErrorModalOpen(false);
                handleConfigSave();
              }}
              disabled={configSaveLoading}
            >
              Retry
            </StyledButton> */}
            <StyledButtonPrimary
              style={{
                width: "204px",
                border: "none",
              }}
              onClick={() => {
                setConfigSaveErrorModalOpen(false);
                handleConfigSave();
              }}
              disabled={configSaveLoading}
            >
              Retry
            </StyledButtonPrimary>
          </Column>
        </CustomRow>
      </GenericModal>
    </>
  );
}
