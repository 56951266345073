import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LastUpdated from "../../../assets/images/last_updated.svg";
import moment from "moment";
import DeleteIcon from "../../../assets/images/delete.svg";
const Card = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  background-color: #2f2f2f;
  width: 272px;
  position: relative;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  width: 32px;
  height: 32px;
  background-color: #4b4b4b;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const DonutCircle = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  position: relative;

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    background-color: #4b4b4b;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const TitleContainer = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.div`
  color: #ffffff;
  font-family: "Normal-Demi-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.001em;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: ${(props) =>
    props.applicationStatus === "Published" ? "normal" : "italic"};
`;

const Description = styled.div`
  color: #ffffff;
  font-family: "Normal";
  font-weight: 500;
  font-size: 10px;
  line-height: 15.6px;
  letter-spacing: 0.001em;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: ${(props) =>
    props.applicationStatus === "Published" ? "normal" : "italic"};
`;

const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  color: white;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ContentTitle = styled.div`
  font-weight: 500;
  color: #777777;
  font-size: 10px;
  line-height: 16px;
  font-family: "Normal-Demi-Bold";
`;

const ContentValue = styled.div`
  font-family: "Normal";
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: ${(props) => props.color};
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Cardfooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: #ececec;
  font-size: 12px;
  font-weight: 500;
  font-family: "Normal";
`;

function ApplicationCard({ application, onDelete }) {
  return (
    <Card>
      <DeleteButton onClick={() => onDelete(application.id)}>
        <img src={DeleteIcon} alt="Delete" width={16} height={16} />
      </DeleteButton>
      <CardHeader>
        <Icon>
          <DonutCircle
            color={
              application.applicationStatus === "Published"
                ? "#259D55"
                : "#EDB62E"
            }
          />
        </Icon>
        <TitleContainer>
          <Title applicationStatus={application.applicationStatus}>
            {application.name || "Application"}
          </Title>
          <Description applicationStatus={application.applicationStatus}>
            {application.version
              ? `version: ${application.version}`
              : "version Number Not Available"}
          </Description>
        </TitleContainer>
      </CardHeader>
      <CardContent>
        <ContentBlock>
          <ContentTitle>Repository Name</ContentTitle>
          <ContentValue
            color={
              application.applicationStatus === "Published"
                ? "#259D55"
                : "#EDB62E"
            }
            title={application.repositoryName}
          >
            {application.repositoryName || "--"}
          </ContentValue>
        </ContentBlock>
        <ContentBlock>
          <ContentTitle>Application Status</ContentTitle>
          <ContentValue
            color={
              application.applicationStatus === "Published"
                ? "#259D55"
                : "#EDB62E"
            }
          >
            {application.applicationStatus}
          </ContentValue>
        </ContentBlock>
      </CardContent>
      <Cardfooter>
        <img
          src={LastUpdated}
          alt="Last Updated"
          style={{ marginRight: "8px" }}
        />
        Last Updated {moment(application.updatedAt * 1000).fromNow()}
      </Cardfooter>
    </Card>
  );
}

ApplicationCard.propTypes = {
  application: PropTypes.shape({
    name: PropTypes.string,
    version: PropTypes.string,
    repositoryName: PropTypes.string,
    applicationStatus: PropTypes.string,
    updatedAt: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ApplicationCard;
