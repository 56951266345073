import React from "react";

const NewButton = (props) => {
  const { width, height, fontSize, backgroundColor, text, margin, onClick } =
    props;

  const handleClick = (e) => {
    if (onClick) {
      onClick(e); // Call the onClick prop if it's provided
    }
  };

  return (
    <div
      style={{
        width: width ? width : "204px",
        height: height ? height : "48px",
        border: "none",
        backgroundColor: backgroundColor,
        fontSize: fontSize,
        color: "#fafafa",
        border: "1px solid #fafafa",
        borderRadius: "12px",
        margin: margin,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        transition: "transform 0.2s ease", // Add the transition property for smooth animation
        fontFamily: "Normal",
      }}
      // Use :hover pseudo-class to apply the transform on hover
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "scale(1.05)"; // Scale up to 1.05 on hover
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)"; // Return to the original size on hover out
      }}
      onClick={handleClick} // Call the handleClick function on click
    >
      {text}
    </div>
  );
};

export default NewButton;
