// External imports
import React from "react";
import PropTypes from "prop-types";
// import styled from "styled-components";
import "../../scss/PreBuiltConnectUpdated.scss";
import { Row, Col, Container } from "react-bootstrap";
import { ConnectorsContext } from "../../pages/WorkSpaceConnectors/WorkSpaceConnectorsMainPage";
import { useContext, useState } from "react";

import ConnectorIcon from "../../assets/images/Connectors.svg";

const truncateContent = (content, maxLength = 50) => {
  return content.length > maxLength
    ? `${content.slice(0, maxLength)}...`
    : content;
};
const hideContent = (content, maxLength = 25) => {
  return content.length > maxLength
    ? `${content.slice(0, maxLength)} `
    : content;
};

// Styled components
// const Container = styled.div`
//   display: flex;
//   width: 286px;
//   height: 168px;
//   background-color: #2f2f2f;
//   border-radius: 12px;
//   color: #fafafa;
//   font-family: "Normal";
// `;

// const Line = styled.div`
//   width: 4px;
//   height: 142px;
//   background-color: #47ccd6;
//   margin-left: 12px;
//   margin-top: 12px;
//   border-radius: 5px;
// `;

// const CardContent = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-left: 12px;
//   gap: 12px;
// `;

// const Row = styled.div`
//   display: flex;
// `;

// const IconContainer = styled.div`
//   border-radius: 8px;
//   background-color: #393939;
//   height: 32px;
//   width: 32px;
//   margin-top: 16px;
// `;

// const Icon = styled.div`
//   height: 24px;
//   width: 24px;
//   margin: 4px;
// `;

// const Title = styled.div`
//   font-size: 16px;
//   font-weight: 600;
//   line-height: 41px;
//   margin-left: 8px;
//   margin-top: 12px;

// `;

// const Info = styled.div`
//   font-size: 12px;
//   font-weight: 500;
//   width: 96px;
//   line-height: 15.6px;
// `;
// const Info2 = styled.div`
//   font-size: 12px;
//   font-weight: 500;
//   margin-left: 54px;
//   line-height: 15.6px;
// `;

// const Label = styled.div`
//   opacity: 0.72;
// `;

// const Value = styled.div`
//   color: #47ccd6;
//   text-align: left;
// `;

// const Description = styled.div`
//   width: 251px;
//   font-size: 12px;
//   font-weight: 500;
//   margin-right: 7px;
//   margin-bottom: 17px;
//   line-height: 15.6px;
//   letter-spacing: 0.02em;
//   text-align: left;
// `;

const getConnectorColor = (connectionType) => {
  switch (connectionType) {
    case "Input":
      return "#47CCD6";
    case "INPUT":
      return "#47CCD6";
    case "Output":
      return "#F6F6F6";
    case "OUTPUT":
      return "#F6F6F6";
    default:
      return "#C7A6F1";
  }
};

/**
 * PreBuiltCard component renders a card displaying information about a connector.
 *
 * @param {string} props.title - The title of the connector.
 * @param {string} props.category - The category of the connector.
 * @param {string} props.type - The type of the connector.
 * @param {string} props.description - The description of the connector.
 * @param {} icon - The link of icon of that particular type connector.
 *
 * @returns {JSX.Element} The rendered PreBuiltCard component.
 */

const PreBuiltCard = ({
  title = "NA",
  category = "NA",
  type = "NA",
  description = "NA",
  icon,
  id,
  isAvailable,
}) => {
  const { handlePreConnectorClick } = useContext(ConnectorsContext);

  return (
    <Container>
      <Row
        noGutters
        className="cardcontainer"
        onClick={() =>
          handlePreConnectorClick(
            title ? title : title,
            type ? type : "INPUT",
            id,
            category,
            description,
          )
        }
      >
        <Col xs={1}>
          <div
            className="leftline"
            style={{
              marginTop: "12px",
              marginBottom: "14px",
              backgroundColor: getConnectorColor(type),
            }}
          />
        </Col>
        <Col xs={11}>
          <Row noGutters style={{ marginTop: "18px" }}>
            <Col
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Row noGutters style={{ width: "100%" }}>
                <Col xs={2} className="g-0">
                  <div className="connectorlogoarea">
                    <img
                      src={icon ? icon : ConnectorIcon}
                      alt="Connector Icon"
                      style={{ maxHeight: "24px", maxWidth: "24px" }}
                    />
                  </div>
                </Col>
                <Col xs={10} className="connectorname g-0">
                  {title}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row noGutters style={{ marginTop: "10px" }}>
            <Col xs={7}>
              <Row
                style={{
                  color: "#777777",
                  fontSize: "10px",
                  fontWeight: "600",
                  fontFamily: "Normal-Demi-Bold",
                }}
              >
                Category
              </Row>
              <Row
                className="category"
                style={{ color: getConnectorColor(type), fontFamily: "Normal" }}
              >
                {category}
              </Row>
            </Col>
            <Col xs={5}>
              <div className="connectorcategoryarea">
                <div
                  style={{
                    fontSize: "10px",
                    color: "#777777",
                    fontWeight: "600",
                  }}
                >
                  Type
                </div>
                <div
                  className="category"
                  style={{
                    color: getConnectorColor(type),
                    fontFamily: "Normal",
                  }}
                >
                  {type}
                </div>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "10px",
              marginBottom: "12px",
              fontFamily: "Normal",
            }}
          >
            <Row className="description">{truncateContent(description)}</Row>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

// Prop types
PreBuiltCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default PreBuiltCard;
