import React, { useEffect, useState } from "react";
import styled from "styled-components";

import LandingApplicationPage from "./LandingApplicationPage";
import { Col, Container, Row } from "reactstrap";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import ApplicationList from "./ApplicationList";
// import SkeletonLoading from "../../components/common/_skeletonLoading";
import loadingAnimation from "../../assets/images/Loading Animation.gif";

const PageContainer = styled.div`
  width: 100%;
  background-color: black;
  overflow: "auto";
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
`;
const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95vh;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;

function ApplicationPage() {
  const [applicationData, setApplicationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const workspaceId = parseInt(localStorage.getItem("selectedWorkSpaceId"));

  useEffect(() => {
    async function fetchApplication() {
      try {
        const response = await axiosAdapter(
          "POST",
          `${env.REACT_APP_URL}/pipeline/getAllApplications`,
          JSON.stringify({ workspaceId: workspaceId }),
        );
        setApplicationData(response.data.data);
      } catch (err) {
        setError("Failed to fetch applications. Please try again later.");
      } finally {
        setLoading(false);
      }
    }
    fetchApplication();
  }, []);

  const handleDelete = (deletedId) => {
    setApplicationData((prevData) =>
      prevData.filter((app) => app.id !== deletedId),
    );
  };

  return (
    <Container fluid style={{ backgroundColor: "black" }}>
      {loading ? (
        <ParentContainer>
          <AnimationContainer>
            <ScrollAnimationBar />
            <LoadingAnimation src={loadingAnimation} alt="Loading Animation" />
          </AnimationContainer>
        </ParentContainer>
      ) : applicationData.length > 0 ? (
        <ApplicationList
          data={applicationData}
          loading={loading}
          onDelete={handleDelete}
        />
      ) : (
        <LandingApplicationPage />
      )}
    </Container>
  );
}

export default ApplicationPage;
