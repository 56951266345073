import React from "react";
import styled from "styled-components";
import ApplicationEmpty from "../../assets/images/Applications-empty-state.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";

const Container = styled.div`
  padding: 40px 66px 0px 66px;
  width: 100%;
  height: calc(100vh - 56px);
`;
const Child = styled.div`
  height: 100vh;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
`;

const Title = styled.div`
  font-family: Normal-Demi-Bold;
  font-size: 48px;
  font-weight: 600;
  line-height: 62.4px;
  color: #f6f6f6;
`;
const Content = styled.div`
  font-family: Normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #cccccc;
  padding-top: 16px;
`;

const ButtonContent = styled.div`
  font-family: Normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #f6f6f6;
`;

function LandingApplicationPage() {
  const history = useHistory();

  const handleClick = () => {
    history.push("/createApplication");
  };
  return (
    <Container>
      <CustomRow>
        <Column xs={9} sm={10} lg={12}>
          <Title>Get Started with Your First Application!</Title>
        </Column>
      </CustomRow>
      <CustomRow>
        <Column xs={9} lg={8.5} xxl={6.5}>
          <Content>
            Looks like you haven’t created any applications yet! Start Creating
            an application to view them here.
          </Content>
        </Column>
      </CustomRow>
      <StyledButtonPrimary
        style={{ width: "204px", marginTop: "20px" }}
        onClick={handleClick}
      >
        Add New Application
      </StyledButtonPrimary>
      <div
        style={{
          height: "calc(100vh - 320px)",
          paddingTop: "48px",
          width: "100%",
        }}
      >
        <img src={ApplicationEmpty} alt="" width="100%"></img>
      </div>
    </Container>
  );
}

export default LandingApplicationPage;
