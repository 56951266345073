import React, { useState } from "react";
import { withRouter } from "react-router-dom";
// import InputField from "../../components/common/_loginInputField";
import CommonLandingPage from "./CommonLandingPage";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ChevronRight from "../../assets/images/Chevron-left.svg";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import styled from "styled-components";
import ShowPasswordIcon from "../../assets/images/show_password_icon.svg";
import DontShowPasswordIcon from "../../assets/images/dont_show_password_icon.svg";

/**
 * NOTE: Structured Component
 * @description This Component is for resetting password.
 * @return {*}
 */

const Text = styled.p`
  font-family: "Normal";
  margin: 0;
`;

const InputField = styled.input`
  font-family: "Normal";
  font-size: 18px;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 12px;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #2f2f2f;
`;

const StyledButton = styled.button`
  border-radius: 12px;
  background-color: #47ccd6;
  border: 1px solid #47ccd6;
  padding: 13px;
  color: #ececec;
  font-family: "Normal";
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  min-height: 56px;
  &:disabled {
    opacity: 0.5;
  }
`;

const ResetPassword = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isResetLoading, setResetLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordInvalid, setPasswordInvalid] = useState(false);

  const checkPassword = () => {
    const passwordRegex = new RegExp(
      /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9!@#$%^&*_])(.{8,15})$/,
    );

    if (passwordRegex.test(password1) === false) {
      setPasswordInvalid(true);
      setErrorMessage(
        "Password must have 8 to 15 aplhanumeric characters, one capital letter, special characters allowed are !@#$%^&*_",
      );
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitClick = async () => {
    let comparePassword;
    setPasswordInvalid(false);
    setResetLoading(true);

    //Input Fields Validation
    let checkPasswordResponse = await checkPassword();

    if (checkPasswordResponse === true) {
      //This comparePassword will return 0 (if passwords match), else -1 will be returned.
      comparePassword = password1.localeCompare(password2);
      if (comparePassword !== 0) {
        comparePassword = false;
        setPasswordInvalid(true);
        setErrorMessage("The passwords do not match.");
      } else {
        comparePassword = true;
      }
    }

    if (checkPasswordResponse === true && comparePassword === true) {
      try {
        let stringifiedData = JSON.stringify({
          clientId: env.REACT_APP_CLIENT_ID,
          otp: location.state?.otp,
          password: password1,
          emailId: location.state?.email,
        });

        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "admin/validateOtpSetCreds",
          stringifiedData,
        );

        setResetLoading(false);
        history.push("/login");
      } catch (err) {
        console.log("err validateOtpSetCreds", err);
        setResetLoading(false);
        if (err?.response?.data?.code === 400) {
          setErrorMessage(err?.response?.data.remarks);
        } else {
          setErrorMessage(
            "Unable to reset the password, please try again later!",
          );
        }
      }
    }
    setResetLoading(false);
  };

  return (
    <CommonLandingPage>
      <CustomRow>
        <Column style={{ marginBottom: "40px" }}>
          <Text
            style={{ fontSize: "22px", lineHeight: "31.2px", color: "#F6F6F6" }}
          >
            Please set a new password for your account
          </Text>
        </Column>
        <Column style={{ marginBottom: "14px" }}>
          <InputFieldContainer
            style={isPasswordInvalid ? { borderColor: "#EA3A3A" } : {}}
          >
            <InputField
              placeholder="Enter Password"
              type={showPassword1 ? "text" : "password"}
              onChange={(e) => setPassword1(e.target.value)}
              value={password1}
            />
            <img
              src={showPassword1 ? DontShowPasswordIcon : ShowPasswordIcon}
              alt="Show Password"
              onClick={() => {
                setShowPassword1(!showPassword1);
              }}
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                height: "18px",
                width: "18px",
              }}
            />
          </InputFieldContainer>
        </Column>
        <Column style={{ marginBottom: errorMessage ? "10px" : "40px" }}>
          <InputFieldContainer
            style={isPasswordInvalid ? { borderColor: "#EA3A3A" } : {}}
          >
            <InputField
              placeholder="Confirm Password"
              type={showPassword2 ? "text" : "password"}
              onChange={(e) => setPassword2(e.target.value)}
              value={password2}
            />
            <img
              src={showPassword2 ? DontShowPasswordIcon : ShowPasswordIcon}
              alt="Show Password"
              onClick={() => {
                setShowPassword2(!showPassword2);
              }}
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                height: "18px",
                width: "18px",
              }}
            />
          </InputFieldContainer>
        </Column>
        {errorMessage && (
          <Column style={{ marginBottom: "12px" }}>
            <Text style={{ color: "#EA3A3A", fontSize: "12px" }}>
              {errorMessage}
            </Text>
          </Column>
        )}
        <Column style={{ marginBottom: "34px" }}>
          <StyledButton
            onClick={handleSubmitClick}
            disabled={!password1 || !password2 || isResetLoading}
          >
            Reset Password
          </StyledButton>
        </Column>
        <Column>
          <Link to="/login">
            <Text style={{ color: "#CCCCCC", fontSize: "16px" }}>
              <img
                src={ChevronRight}
                alt="chevron"
                style={{ height: "12px", width: "12px", marginRight: "10px" }}
              />
              Back to login
            </Text>
          </Link>
        </Column>
      </CustomRow>
    </CommonLandingPage>
  );
};

export default withRouter(ResetPassword);
