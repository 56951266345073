import styled from "styled-components";

const MoreInfoCard = styled.div`
  background-color: #2f2f2f;
  border-radius: 12px;
  height: 68%;
  overflow: auto;
  padding: 24px;
`;

const MoreInfoPart = (props) => {
  const { content } = props;

  return (
    <MoreInfoCard>
      <div
        dangerouslySetInnerHTML={{
          __html: content
            ? content
            : "<div style='height: 100%; width: 100%; text-align: left; font-family: Normal;'>No Config Template</div>",
        }}
        style={{ color: "white" }}
      ></div>
    </MoreInfoCard>
  );
};

export default MoreInfoPart;
