import React, { useState, useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import styled from "styled-components";
import BackIcon from "../../assets/images/leftArrow.png";
import ConnectorIcon from "../../assets/images/Connectors.png";
import AdditionIcon from "../../assets/images/additional_setting.png";
import ConnectorInfoForm from "./ConnectorInfoForm";
import ConnectorLogs from "./ConnectorInfoLog";
import EditConfigurationModal from "./EditConnectorInfoModal";
import GenericModal from "../common/_genericModal";
import Column from "../common/_customColumn";
import { useHistory, useLocation } from "react-router-dom";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import {
  StyledButtonsecondary,
  StyledDeleteButton,
} from "../common/_buttonNewOne";

const Title = styled.div`
  font-family: "Normal-Demi-Bold";
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: #fafafa;
  padding-left: 16px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;
const Button = styled.div`
  width: 204px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease; // Add the transition property for smooth animation
  cursor: pointer;
  &:hover {
    transform: scale(1.05); // Scale up to 1.05 on hover
  }
`;
const ButtonDelete = styled.div`
  width: 204px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease; // Add the transition property for smooth animation
  cursor: pointer;
  &:hover {
    transform: scale(1.05); // Scale up to 1.05 on hover
    background: #993a3a;
  }
  &:active {
    background: #ea3a3a;
  }
`;

const ButtonEdit = styled.div`
  width: 204px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease; // Add the transition property for smooth animation
  cursor: pointer;
  &:hover {
    transform: scale(1.05); // Scale up to 1.05 on hover
    background: transparent;
    border-color: 1px solid #47ccd6;
  }
  &:active {
    background: #1e565a;
  }
`;

const ButtonText = styled.div`
  font-family: "Normal";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #fafafa;
`;
const ButtonTextEdit = styled.div`
  font-family: "Normal";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #fafafa;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background: transparent;
    /* border: #47ccd6; */
    /* color: #47ccd6; */
  }

  &:active {
    background: #1e565a;
    border-color: #47ccd6;
    color: #47ccd6;
  }
`;

const ConnectorInfoTab = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  gap: 8px;
  color: ${(props) => (props.isActive ? "#47ccd6" : "#777777")};
`;
const TabIcon = styled.img`
  height: 24px;
  width: 24px;
  filter: ${(props) => (props.isActive ? "none" : "grayscale(100%)")};
`;

const TabName = styled.div`
  font-family: "Normal-Demi-Bold";
  font-size: 18px;
  font-weight: 600;
`;
const TabLine = styled.div`
  height: 2px;
  width: 90%;
  margin-left: 16px;

  background-color: ${(props) => (props.isActive ? "#47ccd6" : "transparent")};
  margin-top: 8px;
`;

const ModalDescription = styled.div`
  font-family: "Normal";
  font-size: 18px;
  font-weight: 500;
  line-height: 22.4px;
  color: #ececec;
  opacity: 0.7;
`;

export default function ConnectorInfoConfiguration(props) {
  const history = useHistory();
  const location = useLocation();
  const { showMessage } = useContext(SnackbarContext);
  const nodeData = location.state?.nodeData;
  const [selectedTab, setSelectedTab] = useState("connectorInfo");
  const [editModal, setEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLogsExpanded, setIsLogsExpanded] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [allTopics, setAllTopics] = useState([]);

  useEffect(() => {
    (async () => {
      let fetchAllTopics = await axiosAdapter(
        "GET",
        env.REACT_APP_URL + "kafkaadmin/getAllTopics",
      );

      setAllTopics(fetchAllTopics.data.data.topics);
    })();
  }, []);

  // If there is no nodeData in the location state, go back to main page
  if (!nodeData) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  // If there is no nodeData in the location state, go back to main page
  if (!nodeData) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const getWorkspaceIdFromLocalStorage = () => {
    return localStorage.getItem("selectedWorkSpaceId");
  };
  const handleDeleteConnector = async () => {
    const workspaceId = getWorkspaceIdFromLocalStorage();
    try {
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/deleteNamespaceDeploymentNew",
        {
          connectorId: nodeData.configId,
          workspaceId: workspaceId,
          isDevice: nodeData.category === "Telematics Device",
        },
      );

      if (response.status === 200) {
        toggleDeleteModal();
        showMessage("Connector Deleted Successfully", "success");
        setTimeout(() => {
          history.push("/connectorsPlayground");
        }, 1000);
      } else {
        console.error("Failed to delete connector:", response);
        showMessage("Failed to delete connector", "error");
      }
    } catch (error) {
      console.error("Error deleting connector:", error);
      showMessage("Error deleting connector", "error");
    }
  };

  return (
    <div style={{ backgroundColor: "#171717" }}>
      <StyledRow style={{ height: "118px" }}>
        <Column xs={12}>
          <StyledRow style={{ padding: "16px" }}>
            <Column xs={7} style={{ display: "flex", alignItems: "center" }}>
              <Icon
                src={BackIcon}
                alt="Back Icon"
                onClick={() => history.push("/connectorsPlayground")}
              />
              <Title>{nodeData.title}</Title>
            </Column>
            <Column
              xs={5}
              style={{
                display: "flex",
                gap: "16px",
                justifyContent: "flex-end",
              }}
            >
              {/* <Button
                style={{ border: "1px solid var(--white-2, #ECECEC)" }}
                onClick={toggleEditModal}
              >
                <ButtonText>Edit Configuration</ButtonText>
              </Button> */}
              <StyledButtonsecondary
                onClick={toggleEditModal}
                style={{ width: "204px" }}
              >
                Edit Configuration
              </StyledButtonsecondary>
              {/* <Button
                style={{ backgroundColor: "#EA3A3A" }}
                onClick={toggleDeleteModal}
              >
                <ButtonText>Delete Connector</ButtonText>
              </Button> */}
              <StyledDeleteButton
                style={{ width: "204px" }}
                onClick={toggleDeleteModal}
              >
                Delete Connector
              </StyledDeleteButton>
            </Column>
          </StyledRow>
          <StyledRow>
            <Column xs={6} sm={6} md={6} lg={4} xl={2.3}>
              <ConnectorInfoTab
                onClick={() => handleTabChange("connectorInfo")}
                isActive={selectedTab === "connectorInfo"}
              >
                <TabIcon
                  src={ConnectorIcon}
                  alt="img"
                  isActive={selectedTab === "connectorInfo"}
                />
                <TabName>Connector Information</TabName>
              </ConnectorInfoTab>
              <TabLine isActive={selectedTab === "connectorInfo"} />
            </Column>
            <Column
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={2.3}
              style={{ minWidth: "277px" }}
            >
              <ConnectorInfoTab
                // onClick={() => handleTabChange("additionalConfig")}
                style={{ cursor: "not-allowed" }}
                isActive={selectedTab === "additionalConfig"}
              >
                <TabIcon
                  src={AdditionIcon}
                  alt="img"
                  isActive={selectedTab === "additionalConfig"}
                />
                <TabName>Additional Configuration</TabName>
              </ConnectorInfoTab>
              <TabLine isActive={selectedTab === "additionalConfig"} />
            </Column>
          </StyledRow>
          <StyledRow>
            <Column
              xs={12}
              style={{ border: "1px solid #90909052", height: "0" }}
            ></Column>
          </StyledRow>
        </Column>
      </StyledRow>
      <StyledRow style={{ height: "calc(100vh - 174px", overflow: "auto" }}>
        {selectedTab === "connectorInfo" && (
          <>
            <Column xs={5.2} style={{ padding: "16px" }}>
              <ConnectorInfoForm nodeData={nodeData} setConfigs={setConfigs} />
            </Column>
            <Column xs={6.8} style={{ padding: "16px 16px 16px 0px" }}>
              <ConnectorLogs
                isLogsExpanded={isLogsExpanded}
                setIsLogsExpanded={setIsLogsExpanded}
                nodeData={nodeData}
              />
            </Column>
          </>
        )}
        {selectedTab === "additionalConfig" && (
          <Title>Additional Configuration content will come up here</Title> // Additonal Configuration content will come up here
        )}
      </StyledRow>
      <EditConfigurationModal
        toggleEditModal={toggleEditModal}
        isModelOpen={editModal}
        configs={configs}
        setConfigs={setConfigs}
        allTopics={allTopics}
        setAllTopics={setAllTopics}
        nodeData={nodeData}
      />
      {showDeleteModal && (
        <GenericModal
          show={showDeleteModal}
          onClose={toggleDeleteModal}
          title={`Are you sure you want to delete the ${nodeData.title} ?`}
        >
          <Row noGutters>
            <Col xs={12}>
              <ModalDescription>
                {`If you delete ${nodeData.title} , you might break the flow of data in the pipeline and might cause problems in your deployed applications.`}
              </ModalDescription>
            </Col>
          </Row>
          <Row
            noGutters
            style={{
              display: "flex",
              gap: "16px",
              justifyContent: "flex-end",
              marginTop: "89px",
            }}
          >
            {/* <Button
              style={{ border: "1px solid var(--white-2, #ECECEC)" }}
              onClick={toggleDeleteModal}
            >
              <ButtonText>Cancel</ButtonText>
            </Button> */}
            <StyledButtonsecondary
              onClick={toggleDeleteModal}
              style={{ width: "204px" }}
            >
              Cancel
            </StyledButtonsecondary>
            {/* <Button
              style={{ backgroundColor: "#EA3A3A" }}
              onClick={handleDeleteConnector}
            >
              <ButtonText>Delete Connector</ButtonText>
            </Button> */}
            <StyledDeleteButton
              style={{ width: "204px" }}
              onClick={handleDeleteConnector}
            >
              Delete Connector
            </StyledDeleteButton>
          </Row>
        </GenericModal>
      )}
    </div>
  );
}
