import React from "react";
import { Container, Row, Col } from "reactstrap";
import condenseLogo from "../../assets/images/condenseLogo.svg";
import landingImg from "../../assets/images/Login-Image.svg";
import CustomRow from "../../components/common/_customRow";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Text = styled.p`
  font-family: "Normal";
  margin: 0;
`;

const CommonLandingPage = (props) => {
  const history = useHistory();
  return (
    <Container fluid className="landingScreenBackground">
      <Row>
        <Col xs={12} lg={7} className="landingImageAlign">
          <img
            src={landingImg}
            alt="landingpage"
            className="landingPageImage"
          />
        </Col>
        <Col xs={12} lg={5} className="landingSideBar">
          <Row className="landingLeftSideRow">
            <Col xs={12}>
              <div className="landingImage">
                <img
                  src={condenseLogo}
                  className="logoStyle"
                  alt="Condense Logo"
                />
              </div>
            </Col>
            <Col xs={12} className="flexCenter">
              {props.children}
            </Col>
          </Row>
          <CustomRow
            style={{
              flexWrap: "nowrap",
              fontSize: "12px",
              marginBottom: "10px",
              padding: "0px 16px",
            }}
          >
            <Text style={{ color: "#B0B0B0" }}>
              Need more help?{" "}
              <span
                style={{
                  color: "#CCCCCC",
                  fontWeight: "600",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/support")}
              >
                Contact Support
              </span>
            </Text>
            <Text style={{ color: "#CCCCCC" }}>
              All rights reserved © 2024 | Zeliot
            </Text>
          </CustomRow>
        </Col>
      </Row>
    </Container>
  );
};

export default CommonLandingPage;
