import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import LeftArray from "../../../src/assets/images/leftArrow.png";
import DemoIcon from "../../../src/assets/images/demo.jpeg";
import styled from "styled-components";
import CustomRow from "../common/_customRow";
import { StyledButtonsecondary } from "../common/_buttonNewOne";

const DropdownContainer = styled.div`
  background: #2f2f2f;
  position: fixed;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #5e5e5e;
  left: 192px;
  z-index: 1;
  padding: 16px;
  margin-top: 0px;
  min-width: 260px;
`;
const InnerContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-bottom: 16px;
  justify-content: flex-start;
`;
const Logo = styled.img`
  border-radius: 4px;
  height: 32px;
`;

const WorkspaceName = styled.div`
  font-size: 16px;
  font-family: "Normal-Demi-Bold";
  font-weight: 600px;
  color: #f6f6f6;
  margin: 0px 10px 0px 12px;
`;

const StyledButton = styled.button`
  border-radius: 12px;
  border-color: #f6f6f6;
  border-width: 1px;
  border-style: solid;
  background: #2f2f2f;
  cursor: pointer;
  height: 40px;
`;

const StyleButton = styled.button`
  border-radius: 12px;
  border-color: #47ccd6;
  border-width: 1px;
  border-style: solid;
  background: #47ccd6;
  cursor: pointer;
  height: 40px;
`;
const ButtonContent = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-family: "normal";
  color: #f6f6f6;
`;

const Content = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: "normal";
  color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 224px;
  margin: 7px 3px 17px 0px;
`;

const WorkSpaceDropDown = (props) => {
  const history = useHistory();
  const { workSpaces, handleWorkSpaceChange, closeDropdown } = props;
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeDropdown]);

  const handleWorkSpaceClick = (selectedWorkSpace) => {
    handleWorkSpaceChange(
      selectedWorkSpace.name,
      selectedWorkSpace.id,
      selectedWorkSpace.icon_link,
    );
    closeDropdown(); // Call the closeDropdown function passed as prop to close the dropdown
  };

  const handleAllWorkSpaceClick = () => {
    history.push("/");
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      {workSpaces.length == 1 ? (
        <>
          <Content>
            You currently have no other workspaces added.Click on the button
            below to add a new workspace!
          </Content>
          <StyleButton>
            <ButtonContent>Add new Workspace</ButtonContent>
          </StyleButton>
        </>
      ) : (
        <>
          {workSpaces.slice(0, 4).map((eachData, index) => (
            <InnerContainer
              key={eachData.id}
              onClick={() => handleWorkSpaceClick(eachData)}
            >
              <Logo src={eachData.icon_link} alt="" />
              <WorkspaceName>{eachData.name}</WorkspaceName>
            </InnerContainer>
          ))}
          {/* <StyledButton onClick={handleAllWorkSpaceClick}>
            <ButtonContent>View All Workspaces</ButtonContent>
          </StyledButton> */}
          <StyledButtonsecondary onClick={handleAllWorkSpaceClick}>
            View All Workspaces
          </StyledButtonsecondary>
        </>
      )}
    </DropdownContainer>
  );
};

export default WorkSpaceDropDown;
