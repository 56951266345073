import styled from "styled-components";

let buttonWidth;

export const StyledButtonPrimary = styled.button`
  all: unset;
  @media (max-width: 460px) {
    width: 150px;
  }
  @media (min-width: 460px) {
    width: ${(props) => (props.width ? props.width : buttonWidth)};
  }
  height: 48px;
  /* width: 100%; */
  background: ${(props) => (props.disabled ? "#5E5E5E" : "#47CCD6")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-family: "Normal-Demi-Bold";
  font-style: normal;
  font-weight: 600;
  border-radius: 12px;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f6f6f6;

  &:hover {
    background: ${(props) => (props.disabled ? "#5E5E5E" : "#40909a")};
    /* background: #40909a; */
  }
  &:active {
    background: ${(props) => (props.disabled ? "#5E5E5E" : "#47ccd6")};
    /* background: #47ccd6; */
    box-shadow: #000000 25%;
  }
`;

export const StyledRoundedButtonPrimary = styled.button`
  all: unset;
  border-radius: 12px;
  width: 100%;
  background: ${(props) => (props.disabled ? "#2A5F63" : "#47CCD6")};
  font-size: 18px;
  font-weight: 500;
  color: #f6f6f6;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12px 40px;
  display: flex;
`;

export const StyledButtonsecondary = styled.button`
  all: unset;
  @media (max-width: 460px) {
    width: 150px;
  }
  @media (min-width: 460px) {
    width: ${(props) => (props.width ? props.width : buttonWidth)};
  }
  color: #f6f6f6;
  /* border-color: "2px solid #f6f6f6"; */
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-family: "Normal-Demi-Bold";
  /* font-style: normal; */
  font-size: 16px;
  font-weight: 600;
  border-radius: 12px;
  /* width: ${(props) => (props.width ? props.width : buttonWidth)}; */
  height: 48px;
  text-align: center;
  border: 2px solid ${(props) => (props.disabled ? "#797979" : "#FAFAFA")};
  &:hover {
    background: transparent;
    border: 2px solid #47ccd6;
    color: #47ccd6;
  }
  &:active {
    background: #1e565a;
  }
`;
export const StyledDeleteButton = styled.button`
  all: unset;
  @media (max-width: 460px) {
    width: 150px;
  }
  @media (min-width: 460px) {
    width: ${(props) => (props.width ? props.width : buttonWidth)};
  }
  height: 48px;
  /* width: 100%; */
  background: ${(props) => (props.disabled ? "#6a7171" : "#EA3A3A")};
  font-family: "Normal-Demi-Bold";
  font-style: normal;
  font-weight: 600;
  border-radius: 12px;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f6f6f6;
  &:hover {
    background: #993a3a;
  }
  &:active {
    background: #ea3a3a;
    box-shadow: #000000 25%;
  }
`;

export const StyledPrimaryButton2 = styled.button`
  all: unset;
  @media (max-width: 460px) {
    width: 150px;
  }
  @media (min-width: 460px) {
    width: ${(props) => (props.width ? props.width : buttonWidth)};
  }
  height: 48px;
  /* width: 100%; */
  background: ${(props) => (props.disabled ? "#6a7171" : "#EA3A3A")};
  font-family: "Normal-Demi-Bold";
  font-style: normal;
  font-weight: 600;
  border-radius: 12px;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f6f6f6;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background: ${(props) => (props.disabled ? "#6a7171" : "#993a3a")};
  }
  &:active {
    background: #ea3a3a;
    box-shadow: #000000 25%;
  }
`;

export const Button = (props) => {
  const { width, disabled, tag, btnText } = props;

  const handleButtonClick = () => {
    let onClickEvent = props.onClickEvent;
    onClickEvent();
  };

  switch (props.tag) {
    case "large-primary":
      buttonWidth = "408px";
      break;
    case "primary":
      buttonWidth = "240px";
      break;
    case "small-primary":
      buttonWidth = "170px";
      break;
    case "large-secondary":
      buttonWidth = "408px";
      break;
    case "secondary":
      buttonWidth = "240px";
      break;
    case "small-secondary":
      buttonWidth = "170px";
      break;
    case "large-rounded":
      buttonWidth = "100%";
      break;
    default:
      buttonWidth = "240px";
      break;
  }

  return (
    <>
      {tag.includes("primary") && (
        <StyledButtonPrimary
          disabled={disabled ? disabled : false}
          width={width}
          onClick={() => handleButtonClick()}
        >
          {btnText}
        </StyledButtonPrimary>
      )}

      {tag.includes("secondary") && (
        <StyledButtonsecondary
          disabled={disabled ? disabled : false}
          width={width}
          onClick={() => handleButtonClick()}
        >
          {btnText}
        </StyledButtonsecondary>
      )}
      {tag.includes("large-rounded") && (
        <StyledRoundedButtonPrimary
          disabled={disabled ? disabled : false}
          width={width}
          onClick={() => handleButtonClick()}
        >
          {btnText}
        </StyledRoundedButtonPrimary>
      )}
    </>
  );
};

// export default Button;
