import React, { useContext, useState } from "react";
import styled from "styled-components";
import GenericSelection from "../common/_dropDownComponent";
import GenericModal from "../common/_genericModal";
import warning_icon from "../../assets/images/warning_yellow_icon.svg";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import ShowPasswordIcon from "../../assets/images/show_password_icon.svg";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../common/_buttonNewOne";

const Label = styled.div`
  color: #b0b0b0;
  font-family: "Normal";
  font-size: 14px;
  font-weight: 500;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

const CreateNewTopicContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -10px;
  gap: 8px;
`;

const CreateButton = styled.span`
  font-family: Normal;
  line-height: 20.8px;
  font-size: 16px;
`;

const StyledInput = styled.input`
  padding: 8px;
  outline: none;
  background: #2f2f2f;
  width: 100%;
  border-radius: 12px;
  border: 2px solid #909090;
  color: #ececec;
  font-size: 16px;
  font-family: Normal;
  &::placeholder {
    font-family: Normal;
    color: #b0b0b0;
    font-size: 14px;
  }
`;

const WarningText = styled.div`
  margin-top: 10px;
  font-family: Normal;
  font-size: 15px;
  font-style: italic;
  font-weight: 500;
  line-height: 15.6px;
  display: flex;
  align-items: center;
  color: #edb62e;
`;

const ModalButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

// const StyledButton = styled.button`
//   border: 1px solid #ececec;
//   border-radius: 12px;
//   background-color: inherit;
//   padding: 5px 25px;
//   min-height: 45px;
//   max-width: 200px;
//   min-width: calc(0.8 * 200px);
//   color: #ececec;
//   font-family: Normal;
//   font-size: 16px;
//   font-weight: 600;

//   &:disabled {
//     opacity: 0.5;
//   }
// `;

const InputField = styled.input`
  font-family: "Normal";
  font-size: 14px;
  font-weight: 500;
  line-height: 15.6px;
  color: #fafafa;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  &::placeholder {
    color: #b0b0b0;
  }
`;

const InputFieldContainer = styled.div`
  padding: 16px 12px;
  width: 100%;
  border-radius: 12px;
  border: 2px solid #b0b0b0;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NonTelematicsInputForm = ({
  configs,
  setConfigs,
  allTopics,
  setAllTopics,
  setPreviewData,
  connectorVersionList,
  setSelectedConnectorVersion,
  selectedConnectorVersion,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const [newTopicNamesMapper, setNewTopicNamesMapper] = useState({}); // mapper to store the new topicnames entered
  const [newTopicModalMapper, setNewTopicModalMapper] = useState({}); // mapper to store the state of new topic modal
  const [showPassMapper, setShowPassMapper] = useState({}); // mapper to store the state of visibility of password

  // Function to create a new topic and store it if the response is successful
  const createNewTopic = async (index, newTopicName) => {
    try {
      let topicCreationResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "kafkaadmin/createNewTopic",
        JSON.stringify({
          topicName: newTopicName,
        }),
      );
      if (topicCreationResponse.data.status === "success") {
        setAllTopics((prev) => {
          let tempTopics = [...prev, { name: newTopicName }];
          return tempTopics;
        });
        setConfigs((prev) => {
          let tempConfigs = [...prev];
          tempConfigs[index] = {
            ...tempConfigs[index],
            value: { name: newTopicName },
          };
          return tempConfigs;
        });
        setPreviewData((prev) => {
          let prevData = prev;
          prevData.interfaces[index] = {
            name: newTopicName,
            direction: configs[index].direction,
          };
          return prevData;
        });
        setNewTopicNamesMapper((prev) => {
          let tempMap = { ...prev, [index]: null };
          return tempMap;
        });
        setNewTopicModalMapper((prev) => {
          let tempMap = { ...prev, [index]: false };
          return tempMap;
        });
      } else {
        console.log(
          "New topic addition failed",
          topicCreationResponse.data.message,
        );
        showMessage(topicCreationResponse.data.message);
      }
    } catch (err) {
      console.log("Err createNewTopic", err);
      showMessage("Error in creating new topic");
    }
  };

  return (
    <>
      {connectorVersionList.length && selectedConnectorVersion ? (
        <InputContainer>
          <Label>Custom Connector Version</Label>
          <GenericSelection
            dropdownItems={connectorVersionList}
            selectedItem={selectedConnectorVersion}
            placeholder={"Select a Connector Version"}
            onSelect={(item) => {
              setSelectedConnectorVersion(item);
            }}
            nameFunction={(item) => item}
            filterFunction={(arr, text) =>
              arr.filter((item) =>
                item.toLowerCase().includes(text.toLowerCase()),
              )
            }
            searchPlaceHolder={"Search for Versions"}
          />
        </InputContainer>
      ) : null}
      {configs &&
        configs.map((field, index) => {
          if (field.type === "topic") {
            return (
              <InputContainer key={index}>
                <Label>
                  {`${field.label} (${field.direction})`}
                  {field.isRequired && " *"}
                </Label>
                <GenericSelection
                  dropdownItems={allTopics}
                  selectedItem={field.value}
                  placeholder={"Select a Topic"}
                  onSelect={(item) => {
                    let tempConfigs = [...configs];
                    tempConfigs[index] = {
                      ...tempConfigs[index],
                      value: item,
                    };
                    setConfigs(tempConfigs);
                    setPreviewData((prev) => {
                      let prevData = prev;
                      prevData.interfaces[index] = {
                        name: item.name,
                        direction: field.direction,
                      };
                      return prevData;
                    });
                  }}
                  nameFunction={(item) => item.name}
                  filterFunction={(arr, text) =>
                    arr.filter((item) =>
                      item.name.toLowerCase().includes(text.toLowerCase()),
                    )
                  }
                  searchPlaceHolder={"Search for Topics"}
                />
                <CreateNewTopicContainer>
                  <CreateButton style={{ color: "#777777" }}>
                    Can't Find the Topic?
                  </CreateButton>
                  <CreateButton
                    style={{
                      color: "#CCCCCC",
                      textDecoration: "underline",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setNewTopicModalMapper((prev) => {
                        let tempMap = { ...prev, [index]: true };
                        return tempMap;
                      })
                    }
                  >
                    Create a New Topic
                  </CreateButton>
                </CreateNewTopicContainer>
                <GenericModal
                  onClose={() =>
                    setNewTopicModalMapper((prev) => {
                      let tempMap = { ...prev, [index]: false };
                      return tempMap;
                    })
                  }
                  show={newTopicModalMapper[index] || false}
                  title={"Create a New Topic"}
                >
                  <StyledInput
                    placeholder="Add a New Topic Name"
                    value={newTopicNamesMapper[index] || ""}
                    onChange={(e) =>
                      setNewTopicNamesMapper((prev) => {
                        let tempNewTopicMap = {
                          ...prev,
                          [index]: e.target.value,
                        };
                        return tempNewTopicMap;
                      })
                    }
                  />
                  <WarningText>
                    <img
                      src={warning_icon}
                      alt="warning_icon"
                      style={{ marginRight: "5px" }}
                    />
                    A New Topic with this name will be created and available for
                    use
                  </WarningText>
                  <ModalButtonContainer>
                    {/* <StyledButton
                      onClick={() =>
                        setNewTopicModalMapper((prev) => {
                          let tempMap = { ...prev, [index]: false };
                          return tempMap;
                        })
                      }
                    >
                      Cancel
                    </StyledButton> */}
                    <StyledButtonsecondary
                      style={{ width: "204px" }}
                      onClick={() =>
                        setNewTopicModalMapper((prev) => {
                          let tempMap = { ...prev, [index]: false };
                          return tempMap;
                        })
                      }
                    >
                      Cancel
                    </StyledButtonsecondary>
                    {/* <StyledButton
                      style={{ backgroundColor: "#47CCD6" }}
                      disabled={!newTopicNamesMapper[index]?.length}
                      onClick={() =>
                        createNewTopic(index, newTopicNamesMapper[index])
                      }
                    >
                      Create Topic
                    </StyledButton> */}
                    <StyledButtonPrimary
                      style={{ width: "204px" }}
                      disabled={!newTopicNamesMapper[index]?.length}
                      onClick={() =>
                        createNewTopic(index, newTopicNamesMapper[index])
                      }
                    >
                      Create Topic
                    </StyledButtonPrimary>
                  </ModalButtonContainer>
                </GenericModal>
              </InputContainer>
            );
          } else
            return (
              <InputContainer key={index}>
                <Label htmlFor={field.name}>
                  {field.label}
                  {field.isRequired && " *"}
                </Label>
                <InputFieldContainer
                  style={
                    field.type === "password" ? { padding: "12px 12px" } : {}
                  }
                >
                  <InputField
                    type={
                      field.type === "password"
                        ? showPassMapper[index]
                          ? "text"
                          : field.type
                        : field.type === "port"
                        ? "number"
                        : field.type
                    }
                    id={field.name}
                    name={field.name}
                    placeholder={field.placeHolder}
                    required={field.isRequired}
                    value={field.value || ""}
                    onChange={(e) => {
                      let tempConfigs = [...configs];
                      tempConfigs[index] = {
                        ...tempConfigs[index],
                        value: e.target.value,
                        ...(field.label === "Title"
                          ? {
                              name: "title",
                            }
                          : {}),
                      };
                      setConfigs(tempConfigs);
                      if (field.label === "Title") {
                        setPreviewData((prev) => ({
                          ...prev,
                          title: e.target.value,
                        }));
                      }
                    }}
                    min={field.type === "port" ? field.min : undefined}
                    max={field.type === "port" ? field.max : undefined}
                  />
                  {field.type === "password" && (
                    <img
                      src={ShowPasswordIcon}
                      alt="Show Password"
                      onClick={() => {
                        let tempMap = { ...showPassMapper };
                        tempMap[index] = !tempMap[index];
                        setShowPassMapper(tempMap);
                      }}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    />
                  )}
                </InputFieldContainer>
              </InputContainer>
            );
        })}
    </>
  );
};

export default NonTelematicsInputForm;
