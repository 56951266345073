import { React } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import AddCircle from "../../assets/images/add_circle.svg";

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23777' stroke-width='1.5' stroke-dasharray='10' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
  gap: 16px;
  min-height: 160px;
`;

const Icon = styled.img`
  height: 32px;
  width: 32px;
`;

const Text = styled.p`
  font-family: "Normal";
  margin: 0;
`;

const CreateNewConnector = () => {
  const history = useHistory();
  return (
    <CardContainer onClick={() => history.push("/applications")}>
      <Icon src={AddCircle} alt="Add Icon" />
      <Text style={{ color: "#FAFAFA", fontSize: "16px", fontWeight: "600" }}>
        Create a New Connector
      </Text>
    </CardContainer>
  );
};

export default CreateNewConnector;
