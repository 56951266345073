import styled from "styled-components";
import FileIcon from "../../../assets/images/fileExplorer.svg";
// import { data } from "../dummy";
import FolderTree from "./Tree";
import { useContext } from "react";
import CustomAppEditorContext from "../../../context/customApp/CustomAppEditorContext";
import { fetchFileData, getFileExtention } from "../utils";
import { Spinner } from "react-bootstrap";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 200px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  color: #fafafa;
  font-size: 16px;
  font-weight: 600;
  padding: 12px;
`;

const IconContainer = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.label`
  font-weight: bold;
  font-family: "Normal-Demi-Bold";
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  justify-content: center;
  align-items: center;
  overflow: auto;
  font-family: "Normal-Demi-Bold";
`;
function FileExplorer({ data, workSpaceId, repoName, applicationId, loading }) {
  const { customAppEditorData, updateCustomAppEditorData } = useContext(
    CustomAppEditorContext,
  );

  const handleFileClick = async (fileUrl) => {
    // console.log("fileUrl", fileUrl);
    if (fileUrl && fileUrl.fullPath) {
      const fileContent = await fetchFileData(
        fileUrl.fullPath.replace("root/", ""),
        workSpaceId,
        applicationId,
      );

      const newFileData = {
        [fileUrl.id]: {
          name: fileUrl.name,
          path: fileUrl.fullPath,
          content: fileContent,
          saveStatus: {
            color: "purple",
            text: "Autosaved, 5 minutes ago",
          },
          cursorPosition: { line: 0, ch: 0 },
          tabType: { type: "Spaces", size: 2 },
          encoding: "UTF-8",
          language: getFileExtention(fileUrl.name),
          eol: "CRLF",
        },
      };

      updateCustomAppEditorData({
        ...customAppEditorData,
        editorFiles: {
          ...customAppEditorData.editorFiles,
          ...newFileData,
        },
        selectedFile: fileUrl.id,
      });
    }
  };

  return (
    <Container>
      <TitleContainer>
        <IconContainer>
          {FileIcon && <img src={FileIcon} alt="icon" />}
        </IconContainer>
        <Title>File Explorer</Title>
      </TitleContainer>
      {loading ? (
        <Spinner size="4px" style={{ color: "white", marginLeft: "150px" }} />
      ) : (
        <FileContainer>
          {data && <FolderTree data={data} onSelectFile={handleFileClick} />}
        </FileContainer>
      )}
    </Container>
  );
}

export default FileExplorer;
