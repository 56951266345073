import styled from "styled-components";

const MainContainer = styled.div`
  background-color: #4b4b4b;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  opacity: ${(props) => (props.isDisabled ? "0.5" : "1")};
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "normal")};
`;

const Button = styled.button`
  border-radius: 12px;
  background-color: #4b4b4b;
  color: #777777;
  font-size: 16px;
  font-family: "Normal";
  font-weight: 600;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "normal")};
`;

/**
 * Renders a toggle button component with two options.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - toggleValue {any} - The current value of the toggle.
 *   - toggleFunction {function} - The function to be called when a toggle option is clicked.
 *   - text1 {string} - The text to be displayed for the first toggle option button.
 *   - text2 {string} - The text to be displayed for the second toggle option button.
 *   - value1 {any} - The value associated with the first toggle option.
 *   - value2 {any} - The value associated with the second toggle option.
 *   - height {string} - The height of the toggle button container. Defaults to "48px".
 *   - isDisabled {boolean} - Indicates whether the toggle button is disabled.
 * @return {JSX.Element} The rendered toggle button component.
 */

const ToggleButton = ({
  toggleValue,
  toggleFunction,
  text1,
  text2,
  value1,
  value2,
  height = "48px",
  isDisabled,
}) => {
  return (
    <MainContainer style={{ height: height }} isDisabled={isDisabled}>
      <Button
        style={{
          ...(toggleValue === value1
            ? { backgroundColor: "#777777", color: "#F6F6F6" }
            : {}),
        }}
        disabled={isDisabled}
        onClick={() => toggleFunction(value1)}
      >
        {text1}
      </Button>
      <Button
        style={{
          ...(toggleValue === value2
            ? { backgroundColor: "#777777", color: "#F6F6F6" }
            : {}),
        }}
        disabled={isDisabled}
        onClick={() => toggleFunction(value2)}
      >
        {text2}
      </Button>
    </MainContainer>
  );
};

export default ToggleButton;
