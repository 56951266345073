import React from "react";
import { Route, Redirect } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import jwtDecode from "jwt-decode";
import { SnackbarProvider } from "../layouts/Context/snackBarContext";
import TopSnackBar from "../components/common/_topSnackBar";
import { useAuth } from "../AuthContext";
import SideBar from "../components/SideBar/SideBar";
import { Col, Row } from "reactstrap";

/**
 *
 * @description (AppRoute - Defines Page Routes)
 * @param {*} { component: Component, isAuthProtected, ...rest }
 */
const /**
   *
   *
   * @param {*} props
   * @return {*}
   */
  AppRoute = ({ component: Component, isAuthProtected, ...rest }) => {
    const routes = {
      HOME: "/",
      CONNECTORS_PLAYGROUND: "/connectorsPlayground",
      ON_BOARDING: "/onBoarding",
      CONNECTORS: "/connectors",
      APPLICATIONS: "/applications",
      TERMINAL: "/buildLogs",
      TERMINAL_IFRAME: "/terminal-iframe",
      CONNECTOR_INFORMATION: "/connectorInformation",
      // Add more routes here
    };

    const { logout } = useAuth();
    let _automicExpireTime = null;
    let _currentTime = Math.floor(Date.now() / 1000);
    let _token = localStorage.getItem("token");
    if (_token) {
      _automicExpireTime = jwtDecode(_token).exp;
    } else if (!_token && isAuthProtected) {
      return <Redirect to={{ pathname: "/" }}></Redirect>;
    }

    if (
      isAuthProtected &&
      _automicExpireTime &&
      _currentTime > _automicExpireTime
    ) {
      logout();
      return <Redirect to={{ pathname: "/" }} />;
    } else if (
      !isAuthProtected &&
      _automicExpireTime &&
      _currentTime < _automicExpireTime
    ) {
      return <Redirect to={{ pathname: "/onBoarding" }} />;
    }
    // Add the routes where you don't want to render the sidebar
    // Add more routes if needed
    const routeWithoutSkeleton = [routes.TERMINAL_IFRAME];
    const routesWithoutSidebar = [
      routes.TERMINAL,
      routes.ON_BOARDING,
      routes.CONNECTOR_INFORMATION,
    ]; // Add more routes if needed

    // Check if the current route path is in the routesWithoutSidebar array
    const isSideBar = !routesWithoutSidebar.includes(rest.path);
    return (
      <Route
        {...rest}
        render={(props) => {
          if (isAuthProtected) {
            if (routeWithoutSkeleton.includes(rest.path)) {
              return <Component {...props} />;
            }

            return (
              <SnackbarProvider>
                <TopSnackBar />
                {isAuthProtected && <NavBar />}
                <Row noGutters>
                  {isSideBar && (
                    <Col xs={2}>
                      <SideBar />
                    </Col>
                  )}
                  <Col xs={isSideBar ? 10 : 12}>
                    <Component {...props} />
                  </Col>
                </Row>
              </SnackbarProvider>
            );
          } else {
            return (
              <SnackbarProvider>
                <TopSnackBar />
                <Component {...props} />
              </SnackbarProvider>
            );
          }
        }}
      />
    );
  };

export default AppRoute;
