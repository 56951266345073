import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Typography from "../../components/common/_typography";
import RoundedButton from "../../components/common/_roundedButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Applicationcard from "./components/Applicationcard";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";

const PageContainer = styled.div`
  width: 100%;
  background-color: black;
  overflow: "auto";
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
`;

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const LoadingCard = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  background-color: #2f2f2f;
  width: 272px;
  height: 126px;
  position: relative;
  overflow: hidden;
  background: #2f2f2f;
  background: linear-gradient(
    to right,
    #2f2f2f 0%,
    #3a3a3a 20%,
    #2f2f2f 40%,
    #2f2f2f 100%
  );
  background-size: 800px 104px;
  animation: ${shimmer} 1.2s linear infinite;
`;

const HeaderComponent = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
`;

const ContentContainer = styled.div`
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 16px;
  overflow: auto;
  align-items: center;
`;

function ApplicationList({ data, loading, onDelete }) {
  const [applications, setApplications] = useState(data);
  const history = useHistory();
  const workSpaceId = localStorage.getItem("selectedWorkSpaceId");

  useEffect(() => {
    setApplications(data);
  }, [data]);

  const handleDelete = async (id) => {
    const response = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/deleteApplication",
      {
        workspaceId: workSpaceId,
        applicationId: id,
      },
    );
    if (response.status === 200) {
      setApplications((prevApplications) =>
        prevApplications.filter((app) => app.id !== id),
      );
      onDelete(id);
    }
  };
  const handleClick = () => {
    history.push("/createApplication");
  };

  return (
    <PageContainer>
      <HeaderComponent>
        <div>
          <Typography color="#FAFAFA" tag="p-description" text="Applications" />
        </div>
        <div>
          {/* <RoundedButton
            onClick={handleClick}
            width="200px"
            padding="8px 0px"
            text="Add New Application"
          /> */}
          <StyledButtonPrimary
            style={{ width: "204px", height: "40px" }}
            onClick={handleClick}
          >
            Add New Application
          </StyledButtonPrimary>
        </div>
      </HeaderComponent>
      <ContentContainer>
        <Container>
          {loading
            ? Array.from({ length: 20 }).map((_, index) => (
                <LoadingCard key={index} />
              ))
            : applications &&
              applications?.map((application, index) => (
                <Applicationcard
                  application={application}
                  key={index}
                  onDelete={handleDelete}
                />
              ))}
        </Container>
      </ContentContainer>
    </PageContainer>
  );
}

export default ApplicationList;
