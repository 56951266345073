import { React, useState, useEffect, useContext, useRef } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { env } from "../../env";
import { io } from "socket.io-client";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import ExpandIcon from "../../assets/images/expand_button.png";
import MinimiseIcon from "../../assets/images/Minimise.png";
import ConfigLogIcon from "../../assets/images/connector_logs.png";
import Column from "../../components/common/_customColumn";
import CancelIcon from "../../assets/images/cancel_icon.png";
import DownloadIcon from "../../assets/images/download_button.png";
import PlayIcon from "../../assets/images/play_button.png";
import PauseIcon from "../../assets/images/pause_button.png";
import BackIcon from "../../assets/images/leftArrow.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomRow from "../common/_customRow";

const ConnectorLog = styled.div`
  height: 100%;
  background-color: #2f2f2f;
  padding: 16px;
  border-radius: 12px;
  overflow-y: scroll;
  /* &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 30px !important;
    background: var(--Primary-Accent, #47ccd6);
    border-radius: 4px;
  }
  &::-webkit-scrollbar {
    width: 4px;
  } */
`;

const Timer = styled.div`
  font-family: "Normal";
  font-size: 12px;
  font-weight: 500;
  color: #fafafa;
`;

const Title = styled.div`
  font-family: "Normal-Demi-Bold";
  font-size: 20px;
  font-weight: 500;
  color: #fafafa;
  padding-left: 12px;
`;

const TimeUnitArea = styled.div`
  width: 38%;
  background-color: #4b4b4b;
  border: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
`;
const TimeValue = styled.input`
  font-family: "Normal";
  font-size: 12px;
  width: 100%;
  background: none;
  border: none;
  color: #fafafa;
  outline: none;
  text-align: center;
  &::placeholder {
    color: #fafafa;
  }
`;
const MinIcon = styled.img`
  height: 32px;
  width: 32px;
  cursor: pointer;
`;
const LogIcon = styled.img`
  height: 32px;
  width: 32px;
`;
const TimeUnit = styled.div`
  font-family: "Normal";
  font-size: 12px;
  font-weight: 600;
  color: #fafafa;
  padding-left: 8px;
  padding-top: 4px;
`;

const ButtonArea = styled.div`
  border: 1px solid var(--black-2, #2f2f2f);
  background-color: #5e5e5e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;
  cursor: pointer;
`;
const ButtonIcon = styled.img`
  height: 16px;
  width: 16px;
`;
const ExpandLogs = styled.div`
  height: calc(100vh - 56px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #0d0d0d;
  border: 1px solid var(--grey-1, #777777);
  border-radius: 24px 24px 0px 0px;
  padding: 16px;
  margin-top: 72px;
  margin-left: 16px;
  margin-right: 16px;
  overflow-y: scroll;
`;

const Text = styled.p`
  color: #f6f6f6;
  margin: 0;
`;
const ConnectorLogs = ({ isLogsExpanded, setIsLogsExpanded, nodeData }) => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  const [logRepeatTime, setLogRepeatTime] = useState(5);
  const [isPlaying, setIsPlaying] = useState(false);
  const [podLogs, setPodLogs] = useState([]);
  const logSocket = useRef(null);

  useEffect(() => {
    logSocket.current = io(env.REACT_APP_SOCKET_IO_URL, {
      path: "/servicelogs",
    });

    return () => {
      if (logSocket.current) {
        logSocket.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      if (logSocket.current) {
        logSocket.current.on("logs", (message) => {
          setPodLogs((prev) => [
            ...prev,
            ...(message ? message.split("\n").filter((x) => x) : []),
          ]);
        });

        logSocket.current.emit("message", {
          configId: nodeData.configId,
          duration: logRepeatTime,
        });
      }
    } else {
      if (logSocket.current) {
        logSocket.current.off("logs");
      }
    }
    // We are not adding the nodeData and duration dependency because we know for sure that they are there before clicking the play button
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  const handlePlayPause = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      if (parseInt(logRepeatTime) > 1 && parseInt(logRepeatTime) < 1000) {
        setLogRepeatTime(parseInt(logRepeatTime));
        setIsPlaying(true);
      } else {
        showMessage("Please enter a value between 1 and 1000", "error");
      }
    }
  };

  const toggleExpandLogs = () => {
    setIsLogsExpanded(!isLogsExpanded);
  };
  const handleBackIcon = () => {
    history.push("/connectorsPlayground");
  };
  return isLogsExpanded ? (
    <ExpandLogs>
      <CustomRow>
        <Col xs={11} style={{ display: "flex" }}>
          <LogIcon
            src={BackIcon}
            alt=""
            onClick={handleBackIcon}
            style={{ cursor: "pointer" }}
          />
          <Title
            style={{ paddingLeft: "16px" }}
          >{`Logs for ${nodeData.title} `}</Title>
        </Col>
        <Col
          xs={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <MinIcon src={MinimiseIcon} alt="img" onClick={toggleExpandLogs} />
        </Col>
      </CustomRow>
      <CustomRow style={{ paddingTop: "16px", rowGap: "8px" }}>
        {podLogs.map((log, index) => (
          <Column xs={12}>
            <Text>{log}</Text>
          </Column>
        ))}
      </CustomRow>
    </ExpandLogs>
  ) : (
    <ConnectorLog>
      <CustomRow>
        <Column
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          style={{ display: "flex" }}
        >
          <LogIcon src={ConfigLogIcon} alt="" />
          <Title>{`Logs for ${nodeData.title} `}</Title>
        </Column>
        <Column xs={12} sm={12} md={4} lg={4} xl={4}>
          <CustomRow>
            <Column
              xs={12}
              sm={4}
              md={12}
              lg={5.5}
              xl={5.5}
              style={{
                display: "flex",
                backgroundColor: "#5e5e5e",
                border: "1px solid #2f2f2f",
                padding: "5px 8px",
              }}
            >
              <TimeUnitArea>
                <TimeValue
                  value={logRepeatTime}
                  onChange={(e) => {
                    if (/^\d*$/.test(e.target.value)) {
                      setLogRepeatTime(e.target.value);
                      setIsPlaying(false);
                    }
                  }}
                />
              </TimeUnitArea>
              <TimeUnit>Seconds</TimeUnit>
            </Column>
            <Column xs={12} sm={2} md={4} lg={1.625} xl={2.16}>
              <ButtonArea
                onClick={() => {
                  handlePlayPause();
                }}
              >
                <ButtonIcon src={isPlaying ? PauseIcon : PlayIcon} alt="" />
              </ButtonArea>
            </Column>
            <Column xs={12} sm={2} md={4} lg={1.625} xl={2.16}>
              <ButtonArea onClick={() => setPodLogs([])}>
                <ButtonIcon src={CancelIcon} alt="Clear" />
              </ButtonArea>
            </Column>
            <Column xs={12} sm={2} md={4} lg={1.625} xl={2.16}>
              <ButtonArea onClick={toggleExpandLogs}>
                <ButtonIcon src={ExpandIcon} alt="Expand" />
              </ButtonArea>
            </Column>
          </CustomRow>
        </Column>
      </CustomRow>
      <CustomRow style={{ paddingTop: "16px", rowGap: "8px" }}>
        {podLogs.map((log, index) => (
          <Column xs={12}>
            <Text
              style={{
                fontFamily: "'Fira Code', Consolas, 'Courier New', monospace",
              }}
            >
              {log}
            </Text>
          </Column>
        ))}
      </CustomRow>
    </ConnectorLog>
  );
};

export default ConnectorLogs;
