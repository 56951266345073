import { useState } from "react";
import styled from "styled-components";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import DefaultConnectorIcon from "../../assets/images/connector_node_preview_icon.svg";
import SettingsIcon from "../../assets/images/card_setting_icon.svg";
import GoBackIcon from "../../assets/images/card_back_icon.svg";
import EditIcon from "../../assets/images/card_edit_icon.svg";
import DeleteIcon from "../../assets/images/card_delete_icon.svg";
import CustomConnectorDeletionModal from "./CustomConnectorDeletionModal";
import CustomConnectorEditModal from "./CustomConnectorEditModal";

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #2f2f2f;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  min-height: 160px;
`;

const LeftLine = styled.div`
  width: 4px;
  background-color: #ee6f47;
  border-radius: 5px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
`;

const Text = styled.p`
  font-family: Normal;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;

  &.multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const CustomConnectorCard = ({
  connectorId,
  connectorName,
  connectorVersion,
  publisher,
  description,
  iconLink,
  iconFile,
  handleCustomConnectorCardClick,
}) => {
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);

  return (
    <>
      <CardContainer
        style={
          isSettingsOpen
            ? { paddingBottom: "0px", paddingLeft: "0px", paddingRight: "0px" }
            : {}
        }
        onClick={() =>
          handleCustomConnectorCardClick(
            connectorId,
            connectorName,
            description,
            connectorVersion,
          )
        }
      >
        {!isSettingsOpen && <LeftLine />}
        <ContentContainer
          style={!isSettingsOpen ? { paddingLeft: "12px" } : {}}
        >
          <CustomRow
            style={{
              flexWrap: "nowrap",
              columnGap: "12px",
              ...(isSettingsOpen
                ? {
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  }
                : {
                    marginTop: "4px",
                  }),
            }}
          >
            <Column xs={isSettingsOpen ? 2 : 1.4}>
              <img
                src={iconLink || DefaultConnectorIcon}
                alt="Connector Icon"
                style={
                  !isSettingsOpen
                    ? {
                        width: "32px",
                        height: "32px",
                        maxHeight: "32px",
                        maxWidth: "32px",
                      }
                    : {
                        width: "48px",
                        height: "48px",
                        maxHeight: "48px",
                        maxWidth: "48px",
                      }
                }
              />
            </Column>
            <Column xs={isSettingsOpen ? 8 : 8.5}>
              <CustomRow>
                <Column>
                  <Text
                    style={{
                      color: "#F6F6F6",
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontFamily: "Normal-Demi-Bold",
                    }}
                  >
                    {connectorName}
                  </Text>
                </Column>
                {isSettingsOpen && (
                  <Column>
                    <Text
                      style={{
                        color: "#777777",
                        fontSize: "12px",
                        lineHeight: "14px",
                      }}
                    >
                      Published by {publisher}
                    </Text>
                  </Column>
                )}
                {isSettingsOpen && (
                  <Column>
                    <Text
                      style={{
                        color: "#777777",
                        fontSize: "12px",
                        lineHeight: "14px",
                      }}
                    >
                      {connectorVersion}
                    </Text>
                  </Column>
                )}
              </CustomRow>
            </Column>
            <Column
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSettingsOpen(!isSettingsOpen);
              }}
            >
              <img
                src={isSettingsOpen ? GoBackIcon : SettingsIcon}
                alt={isSettingsOpen ? "Go Back Icon" : "Settings Icon"}
                // style={{ cursor: "pointer" }}
              />
            </Column>
          </CustomRow>
          {!isSettingsOpen && (
            <CustomRow>
              <Column xs={7}>
                <TitleAndSubtitleContainer>
                  <Text
                    style={{
                      color: "#777777",
                      fontSize: "10px",
                      lineHeight: "15px",
                      fontFamily: "Normal-Demi-Bold",
                    }}
                  >
                    Published By
                  </Text>
                  <Text
                    style={{
                      color: "#EE6F47",
                      fontSize: "12px",
                      lineHeight: "14px",
                    }}
                  >
                    {publisher}
                  </Text>
                </TitleAndSubtitleContainer>
              </Column>
              <Column xs={5}>
                <TitleAndSubtitleContainer>
                  <Text
                    style={{
                      color: "#777777",
                      fontSize: "10px",
                      lineHeight: "15px",
                      fontFamily: "Normal-Demi-Bold",
                    }}
                  >
                    Version
                  </Text>
                  <Text
                    style={{
                      color: "#EE6F47",
                      fontSize: "12px",
                      lineHeight: "14px",
                    }}
                  >
                    {connectorVersion}
                  </Text>
                </TitleAndSubtitleContainer>
              </Column>
            </CustomRow>
          )}
          <CustomRow
            style={
              isSettingsOpen
                ? {
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  }
                : {
                    marginBottom: "4px",
                  }
            }
          >
            <Column>
              <Text
                className="multiline-ellipsis"
                style={{
                  color: "#F6F6F6",
                  fontSize: "12px",
                  lineHeight: "14px",
                  letterSpacing: "0.02em",
                }}
              >
                {description}
              </Text>
            </Column>
          </CustomRow>
          {isSettingsOpen && (
            <CustomRow
              style={{
                padding: "10px 12px",
                backgroundColor: "#EE6F4766",
                borderRadius: "0px 0px 12px 12px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                return null;
              }}
            >
              <Column xs={4}>
                <Text
                  style={{
                    textDecoration: "underLine",
                    color: "#F6F6F6",
                    fontSize: "12px",
                    lineHeight: "12px",
                    letterSpacing: "0.02em",
                    cursor: "not-allowed",
                  }}
                >
                  Version History
                </Text>
              </Column>
              <Column
                xs={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={EditIcon}
                  alt="Edit Icon"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditOpen(true);
                  }}
                />
                <img
                  src={DeleteIcon}
                  alt="Delete Icon"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteOpen(true);
                  }}
                />
              </Column>
            </CustomRow>
          )}
        </ContentContainer>
      </CardContainer>
      {isDeleteOpen && (
        <CustomConnectorDeletionModal
          customConnectorId={connectorId}
          customConnectorName={connectorName}
          isOpen={isDeleteOpen}
          onClose={() => setDeleteOpen(false)}
        />
      )}
      {isEditOpen && (
        <CustomConnectorEditModal
          customConnectorId={connectorId}
          customConnectorName={connectorName}
          isOpen={isEditOpen}
          onClose={() => setEditOpen(false)}
          customConnectorDescription={description}
          customConnectorVersion={connectorVersion}
          customConnectorIconFile={iconFile}
        />
      )}
    </>
  );
};

export default CustomConnectorCard;
