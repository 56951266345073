import { React, useEffect, useState } from "react";
import styled from "styled-components";
import Column from "../common/_customColumn";
import ErrorIcon from "../../assets/images/node_status_error_icon.svg";
import InspectIcon from "../../assets/images/Inspect.svg";
import RunningIcon from "../../assets/images/loadingicon.svg";
import RunningApplicationIcon from "../../assets/images/node_status_running_icon.svg";
import RunningStoreIcon from "../../assets/images/store_node_status.svg";
import ConnectorInfoIcon from "../../assets/images/connector_info.png";
import ConnectorConfigIcon from "../../assets/images/connector_config.png";
import CustomRow from "../common/_customRow";
import axiosAdapter from "../../utils";
import { env } from "../../env";

const ConnectorForm = styled.div`
  height: 100%;
  background-color: #2f2f2f;
  border-radius: 12px;
  overflow-y: auto;
`;
const ConnectorInfo = styled.div`
  padding: 16px 16px 24px 16px;
`;
const ConnectorInput = styled.div`
  padding: 24px 16px 16px 16px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
`;

const Title = styled.div`
  font-family: "Normal-Demi-Bold";
  font-size: 20px;
  font-weight: 600;
  color: #fafafa;
  padding-left: 12px;
`;

const Label = styled.div`
  font-family: "Normal";
  font-size: 16px;
  font-weight: 600;
  color: #777777;
  padding-top: 24px;
`;

const Value = styled.div`
  font-family: "Normal-Demi-Bold";
  font-size: 18px;
  font-weight: 600;
  color: #f6f6f6;
  padding-top: 6px;
`;

const InputLabel = styled.div`
  font-family: "Normal";
  padding-top: 16px;
  font-size: 14px;
  font-weight: 600;
  color: var(--grey-2, #b0b0b0);
`;

const InputContent = styled.div`
  font-family: "Normal";
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px;
`;
const ConfigDivide = styled.div`
  border: 1px solid #5e5e5e;
`;

const ConnectorInformation = styled.div`
  display: flex;
`;
const ConnectorIcon = styled.img``;

const InputField = styled.input`
  padding: 16px 12px;
  border-radius: 12px;
  border: 2px solid #b0b0b0;
  background-color: #2f2f2f;
  font-family: "Normal";
  font-size: 14px;
  font-weight: 500;
  line-height: 15.6px;
  color: #fafafa;
  &::placeholder {
    color: #b0b0b0;
  }
`;

const NodeStatusContainer = styled.div`
  height: 24px;
  border: 1.5px solid #f6f6f6;
  padding: 0px 7px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(246, 246, 246, 0.16);

  &.Running {
    background: ${(props) => getBackgroundColor(props.category)};
    border-color: ${(props) => getRunningColor(props.category)};
  }

  &.Inspect {
    background: rgba(237, 182, 46, 0.16);
    border-color: #edb62e;
  }

  &.Error {
    background: rgba(234, 58, 58, 0.16);
    border-color: #ea3a3a;
  }
`;

const Text = styled.p`
  font-family: "Normal";
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NodeStatusDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const getRunningColor = (category) => {
  switch (category) {
    case "Store Connector":
      return "#F6F6F6";
    case "Application":
      return "#C7A6F1";
    default:
      return "#47ccd6";
  }
};
const getBackgroundColor = (category) => {
  switch (category) {
    case "Store Connector":
      return "#F6F6F629";
    case "Application":
      return "#C0BBFB29";
    default:
      return "#47ccd629";
  }
};

const RunningStatus = ({ category }) => {
  const getRunningIcon = (category) => {
    switch (category) {
      case "Store Connector":
        return RunningStoreIcon;
      case "Application":
        return RunningApplicationIcon;
      default:
        return RunningIcon;
    }
  };

  return (
    <NodeStatusDiv>
      <img
        src={getRunningIcon(category)}
        alt="Running"
        height="16"
        width="16"
      />
      <Text
        style={{
          fontSize: "14px",
          paddingBottom: "2px",
          color: getRunningColor(category),
        }}
      >
        Running
      </Text>
    </NodeStatusDiv>
  );
};

const InspectStatus = () => (
  <NodeStatusDiv>
    <img
      src={InspectIcon}
      alt="Inspect"
      style={{ height: "16px", width: "16px" }}
    />
    <Text style={{ fontSize: "14px", color: "#EDB62E" }}>Inspect</Text>
  </NodeStatusDiv>
);

const ErrorStatus = () => (
  <NodeStatusDiv>
    <img
      src={ErrorIcon}
      alt="Error"
      style={{ height: "16px", width: "16px" }}
    />
    <Text style={{ fontSize: "14px", color: "#EA3A3A" }}>Error</Text>
  </NodeStatusDiv>
);

const displayNodeStatus = (status, category) => {
  switch (status) {
    case "Running":
      return <RunningStatus category={category} />;
    case "Inspect":
      return <InspectStatus />;
    case "Error":
      return <ErrorStatus />;
    default:
      return <Text style={{ fontSize: "14px", color: "#f6f6f6" }}>NA</Text>;
  }
};

const ConnectorInfoForm = ({ nodeData, setConfigs }) => {
  const [memoryUtilization, setMemoryUtilization] = useState("NA");
  const [cpuUtilization, setCpuUtilization] = useState("NA");
  const [nodeStatus, setNodeStatus] = useState("");
  const [configValues, setConfigValues] = useState(null);

  // As connectorId is mapped to the configId  it's to be changes in backend we have to pass configId not connectorId

  useEffect(() => {
    const fetchResourceUtilization = async () => {
      try {
        const connectorId = nodeData.configId;
        let stringifyData = { connectorId: connectorId };
        let response = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getResourceUtilization",
          stringifyData,
        );

        if (response && response.data && response.data.data) {
          setMemoryUtilization(response.data.data.memoryUsage);
          setCpuUtilization(response.data.data.cpuUsage);
        }
      } catch (error) {
        console.error("Error fetching resource utilization:", error);
      }
    };

    const getNodeStatus = async () => {
      try {
        let stringifiedData = JSON.stringify({
          ids: [nodeData.configId],
        });

        let nodeStatusResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getNodeStatusNew",
          stringifiedData,
        );

        setNodeStatus(nodeStatusResponse.data.data[0].status || "");
      } catch (err) {
        console.log("Error in getNodeStatus", err);
      }
    };

    const fetchConnectorConfigValues = async () => {
      try {
        let stringifiedData = {
          connectorId: nodeData.configId,
          connectorCategory: nodeData.category,
        };
        let configResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getDeployedConnectorConfigs",
          stringifiedData,
        );
        setConfigValues(
          configResponse.data?.data?.configJsonData
            ? JSON.parse(configResponse.data.data.configJsonData)
            : [],
        );
        setConfigs(
          configResponse.data?.data?.configJsonData
            ? JSON.parse(configResponse.data.data.configJsonData).map(
                (obj) => ({
                  ...obj,
                  ...(obj.type === "topic"
                    ? {
                        value: {
                          name: obj.value,
                        },
                      }
                    : { value: obj.value }),
                }),
              )
            : [],
        );
      } catch (err) {
        console.log("Error in fetchConnectorConfigValues", err);
      }
    };

    fetchResourceUtilization();
    getNodeStatus();
    fetchConnectorConfigValues();

    const intervalId = setInterval(() => {
      fetchResourceUtilization();
      getNodeStatus();
    }, 30000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConnectorForm>
      <ConnectorInfo>
        <StyledRow>
          <Column
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ConnectorInformation>
              <ConnectorIcon src={ConnectorInfoIcon}></ConnectorIcon>
              <Title>Connector Information</Title>
            </ConnectorInformation>
            <NodeStatusContainer
              className={nodeStatus}
              category={nodeData.category}
            >
              {displayNodeStatus(nodeStatus, nodeData.category)}
            </NodeStatusContainer>
          </Column>
        </StyledRow>
        <StyledRow>
          <Column xs={4} style={{ display: "flex", flexDirection: "column" }}>
            <Label>Connector Name</Label>
            <Value>{nodeData.name}</Value>
          </Column>
          <Column xs={4} style={{ display: "flex", flexDirection: "column" }}>
            <Label>Connector Category</Label>
            <Value>{nodeData.category}</Value>
          </Column>
          <Column xs={4} style={{ display: "flex", flexDirection: "column" }}>
            <Label>Connector Type</Label>
            <Value>{nodeData.connectionType}</Value>
          </Column>
        </StyledRow>
        <StyledRow>
          <Column xs={4}>
            <Label>Memory Utilisation</Label>
            <Value>{memoryUtilization}</Value>
          </Column>
          <Column xs={4}>
            <Label>CPU Utilisation</Label>
            <Value>{cpuUtilization}</Value>
          </Column>
        </StyledRow>
      </ConnectorInfo>
      <div
        style={{ height: "1px", backgroundColor: "#5e5e5e", marginRight: "0" }}
      ></div>
      <ConnectorInput>
        <StyledRow>
          <Column xs={12} style={{ display: "flex" }}>
            <ConnectorIcon src={ConnectorConfigIcon}></ConnectorIcon>
            <Title>Connector Configurations</Title>
          </Column>
        </StyledRow>
        <CustomRow>
          {configValues ? (
            configValues.length ? (
              configValues.map((config, index) => (
                <Column md={12} lg={12} xl={5.8} key={index}>
                  <InputContent>
                    <InputLabel>{config.label}</InputLabel>
                    <InputField
                      placeholder={config.placeholder}
                      value={config.value}
                      readOnly={true}
                    />
                  </InputContent>
                </Column>
              ))
            ) : (
              <Text style={{ fontSize: "20px", color: "#f6f6f6" }}>
                No Configurations
              </Text>
            )
          ) : (
            <Text style={{ fontSize: "20px", color: "#f6f6f6" }}>
              Loading Configurations
            </Text>
          )}
        </CustomRow>
      </ConnectorInput>
    </ConnectorForm>
  );
};

export default ConnectorInfoForm;
