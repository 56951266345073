import React, { useEffect, useState } from "react";
import styled from "styled-components";
import up_icon from "../../assets/images/up_icon.svg";
import down_icon from "../../assets/images/down_icon.svg";
import search_icon from "../../assets/images/search_icon.svg";
import { Spinner } from "react-bootstrap";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.noMargin ? "0" : "16px")};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  color: #fafafa;
  font-size: 16px;
  font-weight: 600;
  padding: 18px 0px;
  padding: 18px 0px;
  margin-bottom: 8px;
`;

const IconContainer = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.label`
  font-weight: bold;
  font-family: "Normal-Demi-Bold";
`;

const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: "Normal";
`;

const Dropdown = styled.div`
  padding: 12px 12px;
  font-size: 14px;
  width: 100%;
  border-radius: 12px;
  border: ${(props) =>
    props.noMargin ? "1px solid #b0b0b0" : "2px solid #909090"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.disabled ? "#4b4b4b" : "transparent")};
  color: ${(props) => (props.disabled ? "#7a7a7a" : "#cccccc")};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OptionContainer = styled.div`
  background-color: #2f2f2f;
  margin-top: 60px;
  border-radius: 12px;
  border: ${(props) =>
    props.noMargin ? "1px solid #b0b0b0" : "2px solid #909090"};
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  z-index: 10;
`;

const Divider = styled.div`
  height: 2px;
  background-color: #4b4b4b;
`;

const DropdownOptionsContainer = styled.div`
  max-height: 208px;
  background-color: #2f2f2f;
  overflow-y: auto;
  padding: 16px;
  border-radius: 12px;
`;

const DropdownOption = styled.div`
  padding: 10px;
  cursor: pointer;
  color: #cccccc;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.76px;
  background-color: #2f2f2f;

  &:hover {
    background-color: #4b4b4b;
    border-radius: 12px;
  }
`;

const SearchFieldContainer = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledInput = styled.input`
  padding: 8px;
  width: 100%;
  border-top-right-radius: 12px;
  background-color: #2f2f2f;
  border: none;
  color: #cccccc;
  font-family: "Normal";
  font-size: 16px;
  font-weight: 500;
  line-height: 21.76px;
  text-align: justify;
  box-sizing: border-box;
  outline: none;

  &::placeholder {
    color: #5e5e5e;
    font-family: "Normal";
    font-size: 16px;
    font-weight: 500;
    line-height: 21.76px;
    text-align: justify;
  }
`;

const StyledLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 21.76px;
  text-align: center;
  color: #fafafa;
  font: "Normal";
`;

/**
 * GenericSelection component renders a dropdown with customizable options.
 * @param {string} props.icon - The URL of the icon to be displayed (optional).
 * @param {Array} props.dropdownItems - The list of items to be displayed in the dropdown.
 * @param {Object} props.selectedItem - The currently selected item can be a string too.
 * @param {string} props.title - The title of the dropdown (optional).
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {function} props.onSelect - The function to be called when an item is selected.
 * @param {function} props.nameFunction(item) - The function to be called to using item to display name of an item must return a string.
 * @param {function} props.filterFunction(array,string) - The function to be called to filter the dropdown items must return an array.
 * @param {string} props.searchPlaceHolder - To display as placeholder in search field (optional, default is same as placeholder)
 * @param {boolean} props.disable - The boolean value to disable the dropdown.
 * @param {boolean} props.noMargin - The boolean value to remove the margin bottom and reduce the border width from the dropdown.
 * @return {JSX.Element} The rendered GenericSelection component.
 */

const GenericSelection = ({
  icon,
  dropdownItems,
  selectedItem,
  title,
  placeholder,
  loading,
  onSelect,
  nameFunction,
  filterFunction,
  searchPlaceHolder,
  disable,
  noMargin,
  openOnHover,
}) => {
  const [filter, setFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };
  const handleMouseEnter = () => {
    if (openOnHover && !disable) {
      setIsOpen(true);
    }
  };
  const handleMouseLeave = () => {
    if (openOnHover && !disable) {
      setIsOpen(false);
    }
  };
  const handleDropdownClick = () => {
    if (!disable) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (item) => {
    if (!disable) {
      onSelect(item);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setFilteredItems(filterFunction(dropdownItems, filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, dropdownItems]);

  return (
    <Container noMargin={noMargin}>
      {title && (
        <TitleContainer>
          <IconContainer>{icon && <img src={icon} alt="icon" />}</IconContainer>
          <Title>{title}</Title>
        </TitleContainer>
      )}
      <SelectionContainer onMouseLeave={handleMouseLeave}>
        <Dropdown
          onClick={handleDropdownClick}
          disabled={disable}
          onMouseEnter={handleMouseEnter}
          noMargin={noMargin}
          onMouseLeave={handleMouseLeave}
        >
          {selectedItem ? nameFunction(selectedItem) : placeholder}
          {loading ? (
            <Spinner style={{ height: "20px", width: "20px" }} />
          ) : (
            <IconContainer>
              {isOpen ? (
                <img src={up_icon} alt="up_icon" />
              ) : (
                <img src={down_icon} alt="down_icon" />
              )}
            </IconContainer>
          )}
        </Dropdown>
        {isOpen && (
          <OptionContainer noMargin={noMargin} onMouseEnter={handleMouseEnter}>
            <SearchFieldContainer>
              <IconContainer>
                <img src={search_icon} alt="search_icon" />
              </IconContainer>
              <StyledInput
                placeholder={searchPlaceHolder || placeholder}
                value={filter}
                onChange={handleFilterChange}
              />
            </SearchFieldContainer>
            <Divider />
            <DropdownOptionsContainer>
              {filteredItems.length ? (
                filteredItems.map((item, index) => (
                  <DropdownOption
                    key={index}
                    onClick={() => handleOptionClick(item)}
                  >
                    {nameFunction(item)}
                  </DropdownOption>
                ))
              ) : (
                <StyledLabel>No items</StyledLabel>
              )}
            </DropdownOptionsContainer>
          </OptionContainer>
        )}
      </SelectionContainer>
    </Container>
  );
};

export default GenericSelection;
