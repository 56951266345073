import { useState } from "react";
import styled from "styled-components";
import FolderIcon from "../../../assets/images/folder.svg";
import ChevronRight from "../../../assets/images/chevron_right.svg";
import ChevronDown from "../../../assets/images/chevron_down.svg";
import { selectIcon } from "../utils";

const TreeContainer = styled.div`
  overflow: auto;
  padding: 16px;
  width: 100%;
`;

const NodeContainer = styled.div``;

const Node = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: ${(props) => (props.isFolder ? "0px" : "16px")};
  color: #fafafa;
  padding: 12px;
  border-radius: 12px;
  transition: background-color 0.3s ease;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: ${(props) => (props.isActive ? "#3a3a3a" : "transparent")};
  font-family: "Normal";
`;

const NodeContent = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-left: 4px;
  display: flex;
  align-items: center;
  font-family: Normal-Demi-Bold;
`;

const NodeIcon = styled.div`
  margin-right: 8px;
`;

const ExpandIcon = styled.div`
  display: flex;
  align-items: center;
`;

const ChildNodes = styled.div`
  margin-left: 16px;
`;

// eslint-disable-next-line react/prop-types
const FolderTree = ({ data, onSelectFile }) => {
  const [expandedFolders, setExpandedFolders] = useState(new Set());
  const [activeFolder, setActiveFolder] = useState("");

  const toggleFolder = (folderName) => {
    const newExpandedFolders = new Set(expandedFolders);
    if (newExpandedFolders.has(folderName)) {
      newExpandedFolders.delete(folderName);
    } else {
      newExpandedFolders.add(folderName);
    }
    setExpandedFolders(newExpandedFolders);
    setActiveFolder(folderName);
  };

  const renderNode = (node, path) => {
    const fullPath = path ? `${path}/${node.name}` : node.name;
    const isFolder = node.isFolder;
    const isExpanded = node.name === "root" || expandedFolders.has(fullPath);
    const isActive = fullPath === activeFolder;

    return (
      <NodeContainer key={fullPath}>
        <Node
          isFolder={isFolder}
          isActive={isActive}
          onClick={() =>
            isFolder
              ? toggleFolder(fullPath)
              : onSelectFile({ ...node, fullPath })
          }
        >
          <NodeContent>
            <NodeIcon>
              <img
                src={
                  isFolder ? FolderIcon : selectIcon(node.name.split(".")[1])
                }
                width="24px"
                height="24px"
                alt="icon"
              />
            </NodeIcon>
            {node.name}
          </NodeContent>
          {isFolder && (
            <ExpandIcon>
              <img
                src={isExpanded ? ChevronDown : ChevronRight}
                width="15px"
                height="15px"
                alt="icon"
              />
            </ExpandIcon>
          )}
        </Node>
        {isFolder && isExpanded && (
          <ChildNodes>
            {Object.values(node.items).map((childNode) =>
              renderNode(childNode, fullPath),
            )}
          </ChildNodes>
        )}
      </NodeContainer>
    );
  };

  return (
    <TreeContainer>
      <div>{renderNode(data)}</div>
    </TreeContainer>
  );
};

export default FolderTree;
