import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Home from "../../assets/images/home_FILL0_wght400_GRAD0_opsz24.svg";
import connector from "../../assets/images/cable_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import pipeline from "../../assets/images/account_tree_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import applications from "../../assets/images/Applicationsapplications.svg";
import routes from "./RoutePaths";
import { Row } from "reactstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { StyledButtonsecondary } from "../common/_buttonNewOne";

const Logo = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 12px;
  line-height: 41.6px;
  filter: ${(props) =>
    props.isActive || props.isHovered
      ? "brightness(0) saturate(100%) invert(69%) sepia(81%) saturate(398%) hue-rotate(143deg) brightness(93%) contrast(88%)"
      : "none"};
`;

const MenuItem = styled.div`
  align-items: center;
  background: ${(props) => (props.isActive ? "#47CCD629" : "#232323")};
  border: ${(props) =>
    props.isActive
      ? "1px solid var(--Primary-Accent, #47CCD6)"
      : "1px solid transparent"};
  border-radius: ${(props) => (props.isActive ? "10px" : "0")};
  box-sizing: border-box;
  color: ${(props) => (props.isActive ? "#47ccd6" : "#fff")};
  cursor: pointer;
  display: flex;
  height: 40px;
  line-height: 41.6px;
  margin: 16px 13px 16px 17px;
  overflow: hidden;
  padding: 2px 6px 2px 6px;
  position: relative;
  width: 14vw;

  &:hover {
    background: transparent;
    border: 1px solid #47ccd6;
    border-radius: 10px;
    box-sizing: border-box;
    color: #47ccd6;
    height: 40px;
    margin: 16px 14px 16px 17px;
    padding: 2px 6px 2px 6px;
    width: 14vw;
  }
  &:active {
    background: #1e565a;
  }

  @media (max-width: 768px) {
    width: calc(100% - 30px);
  }
`;
const StyledSidebar = styled.div`
  background: #232323;
  border-right: 1px solid #909090;
  height: 100vh;
  z-index: 1;
`;

const Text = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  font-family: "Normal-Demi-Bold";
  height: 24px;
  justify-content: flex-start;
  line-height: 41.6px;
  width: 142px;
`;

const routesNames = [
  {
    name: "Home",
    path: routes.HOME,
    Logo: Home,
  },
  {
    name: "Pipelines",
    path: routes.CONNECTORS_PLAYGROUND,
    Logo: pipeline,
  },
  {
    name: "Connectors",
    path: routes.CONNECTORS,
    Logo: connector,
  },
  {
    name: "Applications",
    path: routes.APPLICATIONS,
    Logo: applications,
  },
];

const SideBar = () => {
  const history = useHistory();
  const location = useLocation();
  const [selectedRoute, setSelectedRoute] = useState(location.pathname);
  const [hoveredRoute, setHoveredRoute] = useState(null);
  const handleClick = (route) => {
    setSelectedRoute(route);
    history.push(route);
  };

  return (
    <Row className="g-0">
      <StyledSidebar>
        {routesNames.length > 0 &&
          routesNames.map((route, index) => (
            <div key={index}>
              <MenuItem
                isActive={selectedRoute === route.path}
                onClick={() => handleClick(route.path)}
                onMouseEnter={() => setHoveredRoute(route.path)}
                onMouseLeave={() => setHoveredRoute(null)}
                style={{ marginTop: "23px" }}
              >
                <Logo
                  src={route.Logo}
                  alt="Logo"
                  isActive={selectedRoute === route.path}
                  isHovered={hoveredRoute === route.path}
                />
                <Text>{route.name}</Text>
              </MenuItem>
            </div>
          ))}
      </StyledSidebar>
    </Row>
  );
};

export default SideBar;
