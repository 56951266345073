// src/Modal.js
import React from "react";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: #2f2f2f;
  padding: 30px;
  border-radius: 24px;
  border: 1px solid #777777;
  width: 60vw;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
  max-height: 100vh;
  @media (min-width: 992px) {
    max-width: 800px;
    /* max-height: 65vh; */
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const Title = styled.h2`
  margin: 0;
  font-family: "Normal-Demi-Bold";
  font-size: 24px;
  color: #f6f6f6;
  font-weight: 600;
  line-height: 41.6px;
  text-align: left;
`;

const Body = styled.div`
  margin-top: 10px;
`;

/**
 * Renders a generic modal component.
 *
 * @param {boolean} props.show - Indicates whether the modal should be shown.
 * @param {function} props.onClose - The function to be called when the modal is closed.
 * @param {string} props.title - The title of the modal.
 * @param {ReactNode} props.children - The content of the modal.
 * @return {ReactElement|null} The rendered modal component or null if the modal should not be shown.
 */
const GenericModal = ({ show, onClose, title, children }) => {
  if (!show) {
    return null;
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Overlay onClick={(e) => onClose && handleOverlayClick(e)}>
      <ModalContainer>
        {title && (
          <Header>
            <Title>{title}</Title>
          </Header>
        )}
        <Body>{children}</Body>
      </ModalContainer>
    </Overlay>
  );
};

export default GenericModal;
