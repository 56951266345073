import React from "react";
import { useState, useEffect, useContext } from "react";
import axiosAdapter from "../../../utils";
import { env } from "../../../env";
import styled from "styled-components";
import Column from "../../../components/common/_customColumn";
import GenericModal from "../../../components/common/_genericModal";
import WorkspaceStatusModal from "./WorkspaceStatusModal";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import SkeletonLoading from "../../../components/common/_skeletonLoading";
import {
  StyledButtonsecondary,
  StyledButtonPrimary,
} from "../../../components/common/_buttonNewOne";

const StyledRow = styled.div`
  display: flex;
  /* flex-wrap: nowrap; */
  margin: 0;
  padding: 0;
`;

const Button = styled.div`
  height: 48px;
  border-radius: 12px;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ButtonText = styled.div`
  font-family: "Normal";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #f6f6f6;
`;

const InputFieldArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const InputField = styled.div`
  border: 2px solid var(--grey-1, #777777);
  /* padding: 16px; */
  border-radius: 8px;
`;
const InputText = styled.input`
  font-family: "Normal";
  font-size: 14px;
  font-weight: 500;
  color: #fafafa;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  &::placeholder {
    color: #b0b0b0;
    opacity: 0.7;
  }
`;
const WorkspaceText = styled.div`
  font-family: "Normal";
  font-size: 18px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  color: #f6f6f6;
  opacity: 0.7;
`;
const ButtonArea = styled.div``;

const CountValue = styled.div`
  color: var(--grey-2, #b0b0b0);
  font-family: "Normal";
  font-size: 14px;
  font-weight: 500;
  padding-top: 6px;
`;
const DescriptionText = styled.textarea`
  font-family: "Normal";
  font-size: 14px;
  font-weight: 500;
  color: #fafafa;
  outline: none;
  border: none;
  background-color: transparent;
  resize: none;
  width: 100%;
  height: 100%;
  &::placeholder {
    color: #b0b0b0;
    opacity: 0.7;
  }
`;

const DateText = styled.div`
  font-family: "Normal";
  font-size: 14px;
  font-weight: 500;
  color: #f6f6f6;
  /* padding: 4px; */
`;

function formatDate() {
  const currentDate = new Date();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return currentDate.toLocaleString("en-US", options);
}
const CreateWorkspaceModal = ({
  isWorkspaceCreationModalOpen,
  onClose,
  handleRefetchWorkSpace,
  handleCloseModal,
  handleClickedWorkSpace,
  workSpaces,
}) => {
  const [workSpaceName, setWorkSpaceName] = useState("");
  const [isModelOpen, setIsModalOpen] = useState(false);
  const [allIcons, setAllIcons] = useState([]);
  const [workSpaceDescription, setWorkSpaceDescription] = useState("");
  const [isWorkSpaceBeingCreated, setIsWorkSpaceBeingCreated] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [createdAt, setCreatedAt] = useState("");
  const [showWorkspaceStatusModal, setShowWorkspaceStatusModal] =
    useState(false);
  const [workspaceCreationSuccessful, setWorkspaceCreationSuccessful] =
    useState(false);
  const [isLoadingIcons, setIsLoadingIcons] = useState(true);
  const [apiResponseStatus, setApiResponseStatus] = useState(null);
  const { showMessage } = useContext(SnackbarContext);
  const [isCreateWorkspaceButtonDisabled, setIsCreateWorkspaceButtonDisabled] =
    useState(false);
  const maxCharacters = 23;
  const minCharacters = 6;

  useEffect(() => {
    const formattedDate = formatDate();
    setCreatedAt(formattedDate);
    getAllIcons();
  }, []);

  const handleAvatarClick = (iconFile) => {
    setSelectedAvatar(iconFile);
  };

  const handleWorkspaceNameChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]*$/; // Regular expression to check for special characters

    if (regex.test(value)) {
      if (value.length <= maxCharacters) {
        setWorkSpaceName(value);
        setIsCreateWorkspaceButtonDisabled(
          value.length < minCharacters || value.length == null,
        );
      }
    } else {
      // Show a message in the snackbar if special characters are entered
      showMessage(
        "Special characters are not allowed in the workspace name",
        "error",
      );
    }
  };

  const showMinimumCharactersRequiredMessage = () => {
    if (isCreateWorkspaceButtonDisabled) {
      showMessage(
        `Minimum ${minCharacters} characters required for Workspace Name`,
        "info",
      );
    }
  };

  const getAllIcons = async () => {
    try {
      setIsLoadingIcons(true); // Set loading state to true before fetching icons
      const getAllIconsResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getAllIcons",
      );
      setAllIcons(getAllIconsResponse.data.data);

      if (getAllIconsResponse.data.data.length > 0) {
        setSelectedAvatar(getAllIconsResponse.data.data[0].icon_file);
      }

      setIsLoadingIcons(false); // Set loading state to false after icons are fetched
    } catch (error) {
      console.error("Error fetching icons:", error);
      setIsLoadingIcons(false); // Set loading state to false in case of error
    }
  };

  const handleWorkspaceDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 130) {
      setWorkSpaceDescription(value);
    }
  };

  const handleCreateWorkSpace = async () => {
    if (
      workSpaceName < 6 ||
      workSpaceDescription === "" ||
      selectedAvatar === null
    ) {
      return;
    } else {
      // Condition block closed properly
      setIsWorkSpaceBeingCreated(true);

      let stringifyData = {
        name: workSpaceName,
        description: workSpaceDescription,
        icon_file: selectedAvatar,
      };

      let setWorkspaceList = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setWorkSpace",
        stringifyData,
      );

      if (setWorkspaceList && setWorkspaceList.data.status === "CREATED") {
        setApiResponseStatus(201);
        setIsWorkSpaceBeingCreated(false);
        setWorkSpaceName("");
        setWorkSpaceDescription("");
        setIsModalOpen(!isModelOpen);
        setWorkspaceCreationSuccessful(true);
        setShowWorkspaceStatusModal(true);
        handleRefetchWorkSpace();
        handleCloseModal();
      } else {
        setApiResponseStatus(400);
        setIsWorkSpaceBeingCreated(false);
        setWorkSpaceName("");
        setWorkSpaceDescription("");
        setIsModalOpen(!isModelOpen);
        setShowWorkspaceStatusModal(true);
        setWorkspaceCreationSuccessful(false);
        showMessage("Failed to create workspace", "error");
      }
    }
  };

  const handleCloseCreateWorkspaceModal = () => {
    onClose();
  };

  return (
    <>
      <GenericModal
        show={isWorkspaceCreationModalOpen}
        onClose={handleCloseCreateWorkspaceModal}
        title="Create a New Workspace"
      >
        <InputFieldArea>
          <StyledRow
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Column xs={5.8}>
              <InputField style={{ height: "48px" }}>
                <StyledRow
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "6px",
                    paddingLeft: "16px",
                  }}
                >
                  <Column xs={9.5}>
                    <InputText
                      placeholder="Name your Workspace"
                      value={workSpaceName}
                      onChange={handleWorkspaceNameChange}
                      maxLength={23}
                      style={{ width: "100%" }}
                    />
                  </Column>
                  <Column xs={2}>
                    <CountValue>{workSpaceName.length}/23</CountValue>
                  </Column>
                </StyledRow>
              </InputField>
            </Column>
            <Column xs={5.8}>
              <InputField
                style={{
                  border: "1px solid #f6f6f6",
                  height: "48px",
                  paddingTop: "12px",
                  paddingLeft: "16px",
                }}
              >
                <DateText>{`Created on ${createdAt}`}</DateText>
              </InputField>
            </Column>
          </StyledRow>
          <StyledRow>
            <Column xs={12}>
              <InputField style={{ height: "96px" }}>
                <StyledRow
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                  }}
                >
                  <Column xs={12}>
                    <DescriptionText
                      placeholder="Describe your Workspace"
                      value={workSpaceDescription}
                      onChange={handleWorkspaceDescriptionChange}
                      maxLength={130}
                    ></DescriptionText>
                  </Column>
                  <Column
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <CountValue style={{ padding: "0" }}>
                      {workSpaceDescription.length}/130
                    </CountValue>
                  </Column>
                </StyledRow>
              </InputField>
            </Column>
          </StyledRow>
          <StyledRow>
            <Column xs={12}>
              <StyledRow
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Column xs={2.5}>
                  <WorkspaceText>Choose a Workspace Icon</WorkspaceText>
                </Column>
                <Column
                  xs={8.8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <StyledRow
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "16px",
                      flexWrap: "wrap",
                    }}
                  >
                    {isLoadingIcons ? (
                      <Column xs={12}>
                        <SkeletonLoading height="14vh" />
                      </Column>
                    ) : (
                      allIcons.map((icon, index) => (
                        <Column xs={2} lg={1.1} key={index}>
                          <div
                            onClick={() => handleAvatarClick(icon.icon_file)}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={icon.icon_link}
                              alt={icon.name}
                              style={{
                                opacity:
                                  selectedAvatar === icon.icon_file ? 1 : 0.4,
                              }}
                            />
                          </div>
                        </Column>
                      ))
                    )}
                  </StyledRow>
                </Column>
              </StyledRow>
            </Column>
          </StyledRow>
        </InputFieldArea>
        <ButtonArea>
          <StyledRow
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "56px",
              gap: "16px",
            }}
          >
            <Column xs={3.8}>
              {/* <Button style={{ border: "1px solid #ECECEC" }} onClick={onClose}>
                <ButtonText>Cancel</ButtonText>
              </Button> */}
              <StyledButtonsecondary
                style={{
                  width: "204px",
                }}
                onClick={onClose}
              >
                Cancel
              </StyledButtonsecondary>
            </Column>
            <Column xs={3.8}>
              {/* <Button
                style={{
                  backgroundColor: "#47ccd6",
                  cursor:
                    workSpaceName.length < 6 ||
                    workSpaceDescription.length === 0 ||
                    workSpaceName.length === 0 ||
                    selectedAvatar == null
                      ? "not-allowed"
                      : "pointer",
                  opacity:
                    workSpaceName.length < 6 ||
                    workSpaceDescription.length === 0 ||
                    workSpaceName.length === 0 ||
                    selectedAvatar == null
                      ? "0.4"
                      : "1",
                }}
                onClick={() => {
                  handleCreateWorkSpace();
                }}
                // onMouseEnter={showMinimumCharactersRequiredMessage}
              >
                <ButtonText>Create Workspace</ButtonText>
              </Button> */}
              <StyledButtonPrimary
                style={{
                  // backgroundColor: "#47ccd6",
                  cursor:
                    workSpaceName.length < 6 ||
                    workSpaceDescription.length === 0 ||
                    workSpaceName.length === 0 ||
                    selectedAvatar == null
                      ? "not-allowed"
                      : "pointer",
                  opacity:
                    workSpaceName.length < 6 ||
                    workSpaceDescription.length === 0 ||
                    workSpaceName.length === 0 ||
                    selectedAvatar == null
                      ? "0.4"
                      : "1",
                  width: "204px",
                }}
                onClick={() => {
                  handleCreateWorkSpace();
                }}
              >
                Create Workspace
              </StyledButtonPrimary>
            </Column>
          </StyledRow>
        </ButtonArea>
      </GenericModal>
      <WorkspaceStatusModal
        show={showWorkspaceStatusModal}
        success={workspaceCreationSuccessful}
        onClose={() => setShowWorkspaceStatusModal(false)}
        apiResponseStatus={apiResponseStatus}
        handleClickedWorkSpace={handleClickedWorkSpace}
        workSpaces={workSpaces}
      />
    </>
  );
};

export default CreateWorkspaceModal;
