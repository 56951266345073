import React from "react";
import styled from "styled-components";

const Card = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #313131;
  width: 221.24px;
  gap: 24px;
`;

const Logo = styled.div`
  width: 126px;
  height: 126px;
  background-color: #232323;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
  }
`;

const Cardheading = styled.div`
  color: #fafafa;
  font-family: "Normal-Demi-Bold";
  font-weight: 600;
  font-size: 18px;
`;

function LoginCard({ name, icon, handleLogin }) {
  return (
    <Card onClick={() => handleLogin(name)}>
      <Logo>
        <img src={icon} alt="Github" />
      </Logo>
      <Cardheading>{name}</Cardheading>
    </Card>
  );
}

export default LoginCard;
