import { Col, Row } from "reactstrap";
import Button from "../../components/common/_button";
import Typography from "../../components/common/_typography";

const ButtonLandingPage = (props) => {
  const handleSubmitClick = () => {
    props.onClickEvent();
  };
  return (
    <Row style={{ marginTop: "40px", gap: "1rem" }}>
      <Col xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Button
          tag={"large-rounded"}
          disabled={props.disabled}
          onClickEvent={handleSubmitClick}
          btnText={props.buttonPlaceholder}
        />
      </Col>
      <Col xs={12}>
        <Typography
          tag={"p-bold-medium"}
          color={" #FAFAFA;"}
          text={props.linkLable}
        />
      </Col>
    </Row>
  );
};

export default ButtonLandingPage;
