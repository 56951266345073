import { Col, Row } from "reactstrap";
import greenTick from "../../assets/images/greenTick.png";
import errorTick from "../../assets/images/errorTick.png";
import Typography from "../../components/common/_typography";
import Button from "../../components/common/_button";

/**
 * NOTE: Common Component
 * @description This Component contains OTP Validation.
 * @param {*} props
 * @return {*}
 */
const CommonValidationStatus = (props) => {
  const { status, title } = props;
  const iconSrc = status === "success" ? greenTick : errorTick;
  const iconColor = status === "success" ? "#37D184" : "#EA3D4A";
  const handleSubmitClick = () => {
    props.handleButtonClick();
  };

  return (
    <Row className="justify-content-center align-items-center">
      <Row className="justify-content-center align-items-center">
        <Col className="successOtpIcon">
          <div style={{ paddingRight: "1.5rem" }}>
            <img src={iconSrc} width="82.5px" height="82.4px" alt="Status" />
          </div>
          <div>
            <Typography text={title} color={iconColor} tag={"head-small"} />
          </div>
        </Col>
      </Row>
      <Col xs={12} className="successOtpDescription">
        <Col xs={12}>
          <Typography
            text={props.description}
            color={" #B7B7B7;"}
            tag={"sub-head-small"}
          />
        </Col>
      </Col>
      <Row className="justify-content-center align-items-center">
        <Col
          xs={12}
          className={
            props.status === "success"
              ? "successOtpButtonMargin"
              : "failedOtpButtonMargin"
          }
        >
          <Button
            tag={"large-primary"}
            btnText={props.buttonPlaceholder}
            onClickEvent={() => handleSubmitClick(props.buttonPlaceholder)}
          />
        </Col>
        <Col xs={12} className="otpCancelStyle">
          <Typography
            tag={"head-x-x-small"}
            color={" #FAFAFA;"}
            text={[
              <a href={props.helperTextRoute}>
                <u>{props.helperText}</u>
              </a>,
            ]}
          ></Typography>
        </Col>
      </Row>
    </Row>
  );
};

export default CommonValidationStatus;
